import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import { FaGift } from 'react-icons/fa'
import image from '../assest/images/NewLogoWhite.png'
import image1 from '../assest/images/peaceemoji.png'
import image2 from "../assest/images/box.png"
import image3 from "../assest/images/NewLogo.png"


const Styles = () => {
  return (
    <Box sx={{backgroundColor:'white'}}>
      <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        padding: 5,
        // background: "#7D3DE7",
       background: "linear-gradient(to right,#FF3FFF, #1B1BDB)"
      }}
    >
      <Box sx={{width:"200px"}}><img src={image}/></Box>
      <Typography  sx={{
      fontSize: "50px",
      color: "white",
      fontFamily: "Arial, sans-serif",
      fontWeight: "bold",
      fontStyle: "italic",
    }}>Gifts & Diamonds</Typography>
      <Avatar sx={{ width: 150, height: 150,backgroundColor:"#E46FFF" }}>
          <FaGift  size={60}></FaGift>
        </Avatar>
         {/* <Box sx={{width:"500px"}}><img src={image3}/></Box> */}
        </Box>

        <Box display="flex" alignItems="center" justifyContent="center"  mt={4} gap={2}>
  <img src={image1} alt="gift" style={{ marginRight: '16px', width: '30%' }} />
  <Box sx={{display:'flex',flexDirection:"column",gap:3}}>
  <Typography sx={{fontSize:22}}>
    <span style={{ fontWeight: 'bold' }}>What are gifts?</span>
    <br />
    Gifts are a fun and exciting way to express your<br/>
     appreciation for your favorite singers or songs.<br/>
    Show your love by sending them a gift!<br/>
  </Typography>
  <Typography sx={{fontSize:22}}>
  <span style={{ fontWeight: 'bold' }}>What do gifts do?</span>
    <br />
    Gifting your favorite singers goes beyond just<br /> 
    liking their performances – it’s a special way to <br />
    show your appreciation!Visit our Explore tab to<br />
    discover recordings that have received the most gifts.<br />
    We’re constantly adding new gifts, so stay tuned!"<br />
  </Typography></Box>
</Box>
<Box display="flex" alignItems="center" justifyContent="center" mt={4} gap={2} >
  <Box sx={{ display: 'flex', flexDirection: "column", gap: 3 }}>
  <Typography sx={{fontSize:22}}>
    <span style={{ fontWeight: 'bold' }}>What are Diamonds?</span>
    <br />
     Diamonds are a virtual currency that you can buy<br />
     and use to send gifts.Show your appreciation <br />
     by gifting your favorite singers with them!"<br />
  </Typography>
  <Typography sx={{fontSize:22}}>
    <span style={{ fontWeight: 'bold' }}>Can I transfer Diamonds?</span>
    <br />
    Diamonds and any gifts you've received cannot be transferred<br />
     to another user.Please note that Diamonds have no cash value,<br /> 
     are solely for redeeming gifts,  and are non-refundable.<br />
      For more details, refer to sparksinger Terms of Service."<br />
  </Typography>
  </Box>
  <img src={image2} alt="gift" style={{ width: '30%' }} />
</Box>
    </Box>
  )
}

export default Styles