import { Box, Button, Card, colors, Typography } from '@mui/material'
import React from 'react'
import { LuLoader } from 'react-icons/lu'
import { Link, useNavigate } from 'react-router-dom'
import { FadeLoader, MoonLoader } from 'react-spinners'
import image from '../assest/images/Bright Color-01.png'

const Spark = () => {
  const handlePrivacy = () => {
    navigate("/about")
  } 
  const handleTerms = () => {
    navigate("/apps")
  } 
  const handleCopyright = () => {
    navigate("/careers")
  } 
  const handleGuidelines = () => {
    navigate("/guidelines")
  } 
  const navigate=useNavigate()
  return (
    <Box sx={{ backgroundColor: '#F7F7F7', padding: 3, display: 'flex', alignItems: 'center' }}>
    <Box sx={{ width: "10%", display: 'flex', justifyContent: 'center', alignItems: 'center',ml:"45%" }}>
    <img
  src={image}
  alt="Logo"
  style={{
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '8px' 
  }}/>
    </Box>
    <Box sx={{ display: 'flex', gap: 2, marginLeft: 'auto' }}>
    <Box onClick={handlePrivacy}
      sx={{
        fontSize: 16,
        borderBottom: '1px solid black',
        cursor: 'pointer',
        '&:hover': {
          color: '#1B1BDB',
          borderBottom: '1px solid #FF3FFF'
        }
      }}
    >
      Privacy Policy
    </Box>
    <Box onClick={handleTerms}
      sx={{
        fontSize: 16,
        borderBottom: '1px solid black',
        cursor: 'pointer',
        '&:hover': {
          color: '#1B1BDB',
          borderBottom: '1px solid #FF3FFF'
        }
      }}
    >
      Terms And Conditions
    </Box>
    <Box onClick={handleCopyright}
      sx={{
        fontSize: 16,
        borderBottom: '1px solid black',
        cursor: 'pointer',
        '&:hover': {
          color: '#1B1BDB',
          borderBottom: '1px solid #FF3FFF'
        }
      }}
    >
      Copyright
    </Box>
    <Box onClick={handleGuidelines}
      sx={{
        fontSize: 16,
        borderBottom: '1px solid black',
        cursor: 'pointer',
        '&:hover': {
          color: '#1B1BDB',
          borderBottom: '1px solid #FF3FFF'
        }
      }}
    >
      Guidelines
    </Box>
    </Box>
  </Box>
)

  
}

export default Spark