import {
    PaymentElement,
    LinkAuthenticationElement,
    AddressElement,
    Elements
  } from '@stripe/react-stripe-js'
  import {useEffect, useRef, useState} from 'react'
  import {useStripe, useElements} from '@stripe/react-stripe-js';
import {  Box, Container } from '@mui/material';
import Card from '@mui/material/Card';
import { FaCheck } from "react-icons/fa6";
import { RiMastercardFill, RiVisaLine } from "react-icons/ri";
import { FaCcMastercard } from "react-icons/fa6";
import { FaCcDiscover } from "react-icons/fa";
import { SiAmericanexpress, SiDiscover } from "react-icons/si"; 
import { FaCcPaypal } from "react-icons/fa";
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import CheckoutForm from './checkout';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { url } from '../config/config';
import AnimatedCouponModal from './couponModal';
  export default function Payment() {


    const initialOptions = {
      "client-id":
          "AT54HEzEkh2WJZZp67iGwYa-lNEd1rLL9TAoziVVnPq68-9rl1XHfFWaIneJNqBUmE33EZ_5qeikheze",
      "enable-funding": "venmo",
      "disable-funding": "paylater,card",
      "buyer-country": "US",
      currency: "USD",
      "data-page-type": "product-details",
      components: "buttons",
      "data-sdk-integration-source": "developer-studio",
  };

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentMethod,setPaymenMethod] = useState(true)
    const [paymentPeriod,setPaymentPeriod] = useState(true)
    const [couponData,setCouponData] = useState([])
    const stripePromise = loadStripe('pk_test_51QL07WD1opJg4h3ahvyCP4JkuIFHpvqHKpW4tDDcQHbz6mLtTgie8Qm6Tx5HPx2Q69ssMiB7m8ZBjRUVXyfQIYnu00uwKkKkUI');
  const [clientSecret, setClientSecret] = useState('');
  const previousPaymentIntentId = useRef(null);
  useEffect(() => {
    const fetchClientSecret = async () => {
      setClientSecret('')
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            };

            const data = {
                amount: paymentPeriod ? 39.99 : 15.99,
                currency: 'usd',
            };
            console.log(previousPaymentIntentId.current,'assignCallll')
            // Cancel previous PaymentIntent if it exists
            if (previousPaymentIntentId.current) {
              console.log('vlauesssTrigerredddwsS')
                try {
                    await axios.post(`${url}/api/Payment/cancel-payment-intent-stripe-web`, { paymentIntentId : previousPaymentIntentId.current }, config);
                    console.log('Previous payment intent cancelled');
                } catch (cancelError) {
                    console.error('Error cancelling previous payment intent:', cancelError);
                }
            }
            console.log(previousPaymentIntentId,'beforeCalll')
            // Create new PaymentIntent
            if(couponData.length === 0){
              const response = await axios.post(`${url}/api/Payment/create-payment-intent-stripe-web`, data, config);
              console.log(response.data, 'clientForStripe');
  
              const newPaymentIntentId = response.data.data.clientSecret.id; // Ensure the backend returns the ID
              previousPaymentIntentId.current = newPaymentIntentId; // Save it for the next run
              setClientSecret(response.data.data.clientSecret.client_secret);
              console.log(previousPaymentIntentId,'dataValuesss')
            }else{
              const data = {
                couponId : couponData[0]?.couponid,
                amount : paymentPeriod ? 39.99 : 15.99,
                currency  : 'usd',
              }
              const response = await axios.post(`${url}/api/Payment/create-discount-payment-intent-stripe-web`,data,config)
              console.log(response.data,'dataValuesssssssssssss')
              const newPaymentIntentId = response.data.data.clientSecret.id; // Ensure the backend returns the ID
              previousPaymentIntentId.current = newPaymentIntentId; // Save it for the next run
              setClientSecret(response.data.data.clientSecret.client_secret);
              console.log(previousPaymentIntentId,'dataValuesss')
            }
        } catch (error) {
            console.error('Error fetching client secret:', error);
        }
    };

    fetchClientSecret();
}, [paymentPeriod,couponData]);
    

useEffect(() => {
  const handleBeforeUnload = (event) => {
    const message = 'Are you sure you want to leave this page? Any unsaved changes may be lost.';
    event.returnValue = message; // For modern browsers
    return message; // For older browsers
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, []);

function calculateDiscountedPrice(price, discount) {
  // Convert the input strings to numbers
  const priceValue = parseFloat(price);
  const discountValue = parseFloat(discount);

  // Validate the inputs
  if (isNaN(priceValue) || isNaN(discountValue)) {
    return "Invalid input. Please provide valid numbers for price and discount.";
  }

  if (discountValue < 0 || discountValue > 100) {
    return "Invalid discount percentage. Please provide a value between 0 and 100.";
  }

  // Calculate the remaining amount
  const discountedPrice = priceValue * (1 - discountValue / 100);

  // Return the result rounded to two decimal places
  return `₹${discountedPrice.toFixed(2)}`;
}

const handlePromocodeGetter = (val) => {
  console.log(val,'parent Functionsssss')
  setCouponData(val)
}
    
    return (
        <Container maxWidth='md' sx={{marginTop:'20px'}}>
          <Box sx={{display:'flex',flexDirection:'column'}}>
            <Box sx={{textAlign:'center'}}>CHOOSE PAYMENT METHOD</Box>
            <Box sx={{display:'flex',justifyContent:'center',gap:5,marginTop:'20px'}}>
              <Box>
                <Card sx={{width:'300px',borderRadius:'10px',border:paymentMethod ? '2px solid rgb(80, 160, 255)' : '',boxShadow:'rgba(0, 0, 0, 0.14) 0px 4px 8px'}} onClick={() => {setPaymenMethod(true)}}>
                  <Box sx={{display:'flex',padding:'25px',gap:3,alignItems:'center'}}>
                    {
                      paymentMethod ?  <Box sx={{backgroundColor:'rgb(80, 160, 255)',borderRadius:'50%',width:'32px',height:'32px',justifyContent:'center',display:'flex',alignItems:'center',cursor:'pointer'}}>
                      <FaCheck fill='white' size={17}/>
                    </Box> : <Box sx={{backgroundColor:'white',borderRadius:'50%',width:'30px',height:'30px',justifyContent:'center',display:'flex',alignItems:'center',cursor:'pointer',border:'2px solid rgb(169 169 169)'}}>
                    </Box>
                    }
                   
                    <Box sx={{display:'flex',flexDirection:'column'}}>
                      <Box sx={{fontSize:'17px',fontWeight:600}}>Credit/Debit Card</Box>
                      <Box sx={{display:'flex',gap:1}}>
                          <RiVisaLine size={25} fill='#424242'/>
                          <RiMastercardFill size={25} fill='#424242'/>
                          <SiDiscover size={25} fill='#424242'/>
                          <SiAmericanexpress size={25} fill='#424242'/>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Box>
              <Box>
              <Card sx={{width:'300px',borderRadius:'10px',boxShadow:'rgba(0, 0, 0, 0.14) 0px 4px 8px',border:paymentMethod ? '' : '2px solid rgb(80, 160, 255)',cursor:'pointer'}} onClick={() => {setPaymenMethod(false)}}>
                  <Box sx={{display:'flex',padding:'25px',gap:3,alignItems:'center'}}>
                    {
                      paymentMethod ?  <Box sx={{backgroundColor:'white',borderRadius:'50%',width:'30px',height:'30px',justifyContent:'center',display:'flex',alignItems:'center',cursor:'pointer',border:'2px solid rgb(169 169 169)'}}>
                    </Box>  : <Box sx={{backgroundColor:'rgb(80, 160, 255)',borderRadius:'50%',width:'32px',height:'32px',justifyContent:'center',display:'flex',alignItems:'center',cursor:'pointer'}}>
                      <FaCheck fill='white' size={17}/>
                    </Box>
                    }
                    <Box sx={{display:'flex',flexDirection:'column'}}>
                      <Box sx={{fontSize:'17px',fontWeight:600}}>PayPal</Box>
                      <Box sx={{display:'flex',gap:1}}>
                           <FaCcPaypal size={25} fill='#424242'/>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Box>
            <Box sx={{textAlign:'center',marginTop:'20px'}}>CHOOSE A PLAN</Box>
            <Box sx={{display:'flex',justifyContent:'center',gap:5,marginTop:'20px'}}>
              <Box>
                <Card sx={{width:'300px',borderRadius:'10px',boxShadow:'rgba(0, 0, 0, 0.14) 0px 4px 8px',border:paymentPeriod ?  '2px solid rgb(80, 160, 255)' : ''}} onClick={() => setPaymentPeriod(true)}>
                  <Box sx={{display:'flex',justifyContent:'flex-end'}}>
                    <Box sx={{background:'rgb(253, 41, 110)',margin:'3px',fontSize:'8px',color:'white',fontWeight:'bold',padding:'5px',borderRadius:'15px',marginBottom:'0'}}>Save 79%</Box>
                  </Box>
                  <Box sx={{display:'flex',padding:'25px',gap:3,alignItems:'center',paddingTop:'3px'}}>
                  {
                      paymentPeriod ?  <Box sx={{backgroundColor:'rgb(80, 160, 255)',borderRadius:'50%',width:'32px',height:'32px',justifyContent:'center',display:'flex',alignItems:'center'}}>
                      <FaCheck fill='white' size={17}/>
                    </Box> : <Box sx={{backgroundColor:'white',borderRadius:'50%',width:'30px',height:'30px',justifyContent:'center',display:'flex',alignItems:'center',cursor:'pointer',border:'2px solid rgb(169 169 169)'}}>
                    </Box>  
                    }
                    <Box sx={{display:'flex',flexDirection:'column',gap:.5}}>
                      <Box sx={{fontSize:'17px',fontWeight:600}}>{paymentMethod ? couponData.length === 0 ?'₹279.93' : calculateDiscountedPrice('279.93',couponData[0]?.percent_off) : '$3.33'} <span style={{fontSize:'14px',fontWeight:'normal'}}>per month</span></Box>
                      <Box>
                      <Box sx={{fontSize:'17px',fontWeight:'noraml'}}>{paymentMethod ? couponData.length === 0 ?'₹3,359.20' : calculateDiscountedPrice('3359.20',couponData[0]?.percent_off) : '$39.99'} - <span style={{fontSize:'14px',fontWeight:'normal'}}>Billed annually</span></Box>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Box>
              <Box>
              <Card sx={{width:'300px',borderRadius:'10px',boxShadow:'rgba(0, 0, 0, 0.14) 0px 4px 8px',border:paymentPeriod ? '' : '2px solid rgb(80, 160, 255)'}} onClick={() => {setPaymentPeriod(false)}}>
                  <Box sx={{display:'flex',padding:'25px',gap:3,alignItems:'center'}}>
                  {
                      paymentPeriod ?   <Box sx={{backgroundColor:'white',borderRadius:'50%',width:'30px',height:'30px',justifyContent:'center',display:'flex',alignItems:'center',cursor:'pointer',border:'2px solid rgb(169 169 169)'}}>
                    </Box> : <Box sx={{backgroundColor:'rgb(80, 160, 255)',borderRadius:'50%',width:'32px',height:'32px',justifyContent:'center',display:'flex',alignItems:'center'}}>
                      <FaCheck fill='white' size={17}/>
                    </Box> 
                    }
                    <Box sx={{display:'flex',flexDirection:'column',gap:.5}}>
                      <Box sx={{fontSize:'17px',fontWeight:600}}>{paymentMethod ? couponData.length === 0 ?'₹1,359.20' : calculateDiscountedPrice('1359.20',couponData[0]?.percent_off) : '$15.99'} <span style={{fontSize:'14px',fontWeight:'normal'}}>per month</span></Box>
                      <Box>
                      <Box sx={{fontSize:'17px',fontWeight:'noraml'}}>{paymentMethod ?  couponData.length === 0 ?'₹1,359.20' : calculateDiscountedPrice('1359.20',couponData[0]?.percent_off)  : '$15.99'} - <span style={{fontSize:'14px',fontWeight:'normal'}}>Billed monthly</span></Box>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Box>
          </Box>
          {
            couponData.length === 0 ? <Box sx={{marginTop:'40px',display:'flex',justifyContent:'center'}}><AnimatedCouponModal func={handlePromocodeGetter}/></Box> : <Box sx={{display:'flex',justifyContent:'center',color:'#673EF7',fontWeight:'bold',textAlign:'center',marginTop:'30px',marginBottom:'10px'}}>{`Congratulations! 🎉 You have successfully applied the coupon and unlocked a flat ${couponData[0]?.percent_off}% discount on your subscription. Enjoy the savings!`}</Box>
           }
          {
            paymentMethod ? <>
              {
                clientSecret && <Elements stripe={stripePromise} options={{clientSecret}}>
                <CheckoutForm clientSecret={clientSecret}/>
              </Elements>
              }
            </> :     
              <Box sx={{marginTop:'25px',display:'flex',flexDirection:'column',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
                <Box sx={{textAlign:'center',fontWeight:400}}>Subscribe</Box>
                <Box sx={{marginTop:'20px',width:'70%'}}>
                <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    
                    style={{
                        shape: "pill",
                        layout: "vertical",
                        color: "blue",
                        label: "paypal",
                    }} 
                    createOrder={async () => {
                        try {
                            const response = await fetch("/api/orders", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                // use the "body" param to optionally pass additional order information
                                // like product ids and quantities
                                body: JSON.stringify({
                                    cart: [
                                        {
                                            id: "YOUR_PRODUCT_ID",
                                            quantity: "YOUR_PRODUCT_QUANTITY",
                                        },
                                    ],
                                }),
                            });

                            const orderData = await response.json();

                            if (orderData.id) {
                                return orderData.id;
                            } else {
                                const errorDetail = orderData?.details?.[0];
                                const errorMessage = errorDetail
                                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                                    : JSON.stringify(orderData);

                                throw new Error(errorMessage);
                            }
                        } catch (error) {
                            console.error(error);
                            setMessage(
                                `Could not initiate PayPal Checkout...${error}`
                            );
                        }
                    }} 
                    onApprove={async (data, actions) => {
                        try {
                            const response = await fetch(
                                `/api/orders/${data.orderID}/capture`,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                }
                            );

                            const orderData = await response.json();
                            // Three cases to handle:
                            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                            //   (2) Other non-recoverable errors -> Show a failure message
                            //   (3) Successful transaction -> Show confirmation or thank you message

                            const errorDetail = orderData?.details?.[0];

                            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                                return actions.restart();
                            } else if (errorDetail) {
                                // (2) Other non-recoverable errors -> Show a failure message
                                throw new Error(
                                    `${errorDetail.description} (${orderData.debug_id})`
                                );
                            } else {
                                // (3) Successful transaction -> Show confirmation or thank you message
                                // Or go to another URL:  actions.redirect('thank_you.html');
                                const transaction =
                                    orderData.purchase_units[0].payments
                                        .captures[0];
                                setMessage(
                                    `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
                                );
                                console.log(
                                    "Capture result",
                                    orderData,
                                    JSON.stringify(orderData, null, 2)
                                );
                            }
                        } catch (error) {
                            console.error(error);
                            setMessage(
                                `Sorry, your transaction could not be processed...${error}`
                            );
                        }
                    }} 
                />
            </PayPalScriptProvider>
                </Box>
              </Box>
          }
       
        </Container>
    )
  }