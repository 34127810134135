import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import axios from 'axios';
import { url } from '../config/config';
import { ExpandMore } from '@mui/icons-material';
import Spark from '../pages/spark';
import { useSelector } from 'react-redux';

function PrivacyPolices() {
  const [expanded, setExpanded] = useState(false);
  const [faqs, setFaqs] = useState([]);
   const scrollValue = useSelector((state) => state)
  useEffect(() => {
    window.scrollTo(0, (scrollValue.Upload.scrollValue * 360))
    // return () => {
    //   dispatch(exploreScrool(0))
    //   };
  }, [scrollValue])
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    const detailsfaq = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/FAQ/getFAQ`,config)
        console.log(res,"FAQQQQQQQQQQQQQQQQQ")
        setFaqs(res.data.data)
        
      } catch (error) {
        console.log(error)
      }
    }
    detailsfaq()
  }, [])
  return (
    <Box sx={{ padding: 2, backgroundColor: '#F7F7F7' }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        This privacy policy applies to the Spark Singer app (hereby referred to as "Application") for mobile devices that was created by SoftSpark Integrated Transformation Pvt., Ltd., (hereby referred to as "Service Provider") as a Commercial service. This service is intended for use "AS IS".
      </Typography>

      <Typography variant="h6" gutterBottom>
        Information Collection and Use
      </Typography>
      <Typography variant="body1" paragraph>
        The Application collects information when you download and use it. This information may include information such as:
      </Typography>
      <ul>
        <li>Your device's Internet Protocol address (e.g. IP address)</li>
        <li>The pages of the Application that you visit, the time and date of your visit, the time spent on those pages</li>
        <li>The time spent on the Application</li>
        <li>The operating system you use on your mobile device</li>
      </ul>
      <Typography variant="body1" paragraph>
        The Application does not gather precise information about the location of your mobile device.
      </Typography>
      <Typography variant="body1" paragraph>
        The Application collects your device's location, which helps the Service Provider determine your approximate geographical location and make use of in below ways:
      </Typography>
      <ul>
        <li><strong>Geolocation Services:</strong> The Service Provider utilizes location data to provide features such as personalized content, relevant recommendations, and location-based services.</li>
        <li><strong>Analytics and Improvements:</strong> Aggregated and anonymized location data helps the Service Provider to analyze user behavior, identify trends, and improve the overall performance and functionality of the Application.</li>
        <li><strong>Third-Party Services:</strong> Periodically, the Service Provider may transmit anonymized location data to external services. These services assist them in enhancing the Application and optimizing their offerings.</li>
      </ul>
      <Typography variant="body1" paragraph>
        The Service Provider may use the information you provided to contact you from time to time to provide you with important information, required notices and marketing promotions.
      </Typography>
      <Typography variant="body1" paragraph>
        For a better experience, while using the Application, the Service Provider may require you to provide us with certain personally identifiable information, including but not limited to Email, userId, gender. The information that the Service Provider requests will be retained by them and used as described in this privacy policy.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Third Party Access
      </Typography>
      <Typography variant="body1" paragraph>
        Only aggregated, anonymized data is periodically transmitted to external services to aid the Service Provider in improving the Application and their service. The Service Provider may share your information with third parties in the ways that are described in this privacy statement.
      </Typography>
      <Typography variant="body1" paragraph>
        The Service Provider may disclose User Provided and Automatically Collected Information:
      </Typography>
      <ul>
        <li>As required by law, such as to comply with a subpoena, or similar legal process;</li>
        <li>When they believe in good faith that disclosure is necessary to protect their rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;</li>
        <li>With their trusted services providers who work on their behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</li>
      </ul>

      <Typography variant="h6" gutterBottom>
        Opt-Out Rights
      </Typography>
      <Typography variant="body1" paragraph>
        You can stop all collection of information by the Application easily by uninstalling it. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Data Retention Policy
      </Typography>
      <Typography variant="body1" paragraph>
        The Service Provider will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. If you'd like them to delete User Provided Data that you have provided via the Application, please contact them at raj.mahamuni@softspark.com and they will respond in a reasonable time.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Children
      </Typography>
      <Typography variant="body1" paragraph>
        The Service Provider does not use the Application to knowingly solicit data from or market to children under the age of 13.
      </Typography>
      <Typography variant="body1" paragraph>
        The Service Provider does not knowingly collect personally identifiable information from children. The Service Provider encourages all children to never submit any personally identifiable information through the Application and/or Services. The Service Provider encourages parents and legal guardians to monitor their children's Internet usage and to help enforce this Policy by instructing their children never to provide personally identifiable information through the Application and/or Services without their permission. If you have reason to believe that a child has provided personally identifiable information to the Service Provider through the Application and/or Services, please contact the Service Provider (raj.mahamuni@softspark.com) so that they will be able to take the necessary actions. You must also be at least 16 years of age to consent to the processing of your personally identifiable information in your country (in some countries we may allow your parent or guardian to do so on your behalf).
      </Typography>

      <Typography variant="h6" gutterBottom>
        Security
      </Typography>
      <Typography variant="body1" paragraph>
        The Service Provider is concerned about safeguarding the confidentiality of your information. The Service Provider provides physical, electronic, and procedural safeguards to protect information the Service Provider processes and maintains.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Changes
      </Typography>
      <Typography variant="body1" paragraph>
        This Privacy Policy may be updated from time to time for any reason. The Service Provider will notify you of any changes to the Privacy Policy by updating this page with the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.
      </Typography>
      <Typography variant="body1" paragraph>
        This privacy policy is effective as of 2024-09-30.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Your Consent
      </Typography>
      <Typography variant="body1" paragraph>
        By using the Application, you are consenting to the processing of your information as set forth in this Privacy Policy now and as amended by us.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions regarding privacy while using the Application, or have questions about the practices, please contact the Service Provider via email at <a href="mailto:raj.mahamuni@softspark.com">raj.mahamuni@softspark.com</a>.
      </Typography>
      <Box>
      <Typography  variant="h6" gutterBottom>
        Managing And Deleting Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We provide the ability to correct or update the information you provided during registration via your account settings on our website, the app, or by contacting support through our email <a href="mailto:support@sparksinger.com">support@sparksinger.com</a>.
      </Typography>
      <Typography variant="body1" paragraph>
        You can delete your account through the profile settings in the mobile app. Alternatively, you can request the deletion of your Sparksinger account by reaching out to our support team through the email <a href="mailto:support@sparksinger.com">support@sparksinger.com</a>.
      </Typography>
      <Typography variant="body1" paragraph>
        We retain your information in our server logs, databases, and records only for as long as necessary to fulfill the purposes outlined in this policy, subject to any legal obligations requiring us to retain such information. Third-party service providers may be used to store and manage this data on our behalf.
      </Typography>
      <Typography variant="body1" paragraph>
        Verification-related information is retained for a reasonable period, even if you do not complete all verification steps. This helps facilitate future verifications and prevents abuse of our verification processes.
      </Typography>
      <Typography variant="body1" paragraph>
        If you create content with other users, the recorded performances that are part of such group collaborations will remain accessible even after your account is deleted.
      </Typography>

      <Box sx={{ display: "flex", flexDirection: 'column', width: "60%", padding: 6, gap: 3, margin: '0 auto' }}>
      <Typography sx={{ fontSize: 30, fontWeight: "bold" }}>FAQ</Typography>
      {faqs&&faqs.map((faq, index) => (
        <Accordion key={index} sx={{ backgroundColor: "#F7F7F7" }} expanded={expanded === `panel${index + 1}`} onChange={handleChange(`panel${index + 1}`)}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography sx={{ fontSize: '1.2rem', color: "#7D3DE7", fontWeight: "bold", fontSize: 20 }}>
              {faq.Question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {faq.Answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
      </Box>
    <Box><Spark/></Box>
    </Box>
  );
}

export default PrivacyPolices;
