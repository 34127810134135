import { Box, Card, CircularProgress, Modal } from "@mui/material";
import { AddressElement, LinkAuthenticationElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useState } from "react";
import { url } from "../config/config";
import { FcApproval } from "react-icons/fc";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const CheckoutForm = ({clientSecret}) => {
    console.log(clientSecret,'changinggggClient')
    const stripe = useStripe();
    const elements = useElements();
    const [modalView,setModalView] = useState(false)
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [modalContent,setModalContent] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
          // Ensure Stripe.js has loaded before submitting
          return;
        }
      
        setIsLoading(true);
        setModalView(true)
        try {
          const { paymentIntent, error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
    
            },
            redirect: 'if_required',
          });
          console.log(paymentIntent, 'successResponse');
          if (error) {
            if (error.type === "card_error" || error.type === "validation_error") {
              setMessage(error.message);  
            } else {
              setMessage("An unexpected error occurred.");
            }
          } else if (paymentIntent) {
            console.log(paymentIntent, 'successResponse');
            const config = {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          };
            const Response = await axios.post(`${url}/api/Payment/verify-payment-stripe-web`,{paymentIntentId : paymentIntent.id},config)
            setModalContent(true)
            console.log(Response.data,'dataValuessssss')
            // Send the PaymentIntent ID to the backend for verification
          }
        } catch (err) {
          console.error('Error in payment handling:', err);
          setMessage('An error occurred while processing payment.');
        } finally {
          setIsLoading(false);
        }
      };

    return(
        <>
        <Card sx={{padding:'40px',margin:'40px 100px',marginBottom:'20px'}}>
<form id="payment-form" onSubmit={handleSubmit}>
<LinkAuthenticationElement id="link-authentication-element"
  // Access the email value like so:
  // onChange={(event) => {
  //  setEmail(event.value.email);
  // }}
  //
  // Prefill the email field like so:
  // options={{defaultValues: {email: 'foo@bar.com'}}}
  />
<PaymentElement  id="payment-element" />
<AddressElement options={{
mode:'billing'
}} />

</form>
</Card>
<Box sx={{width:'100%',display:'flex',justifyContent:'center'}}>
<button style={{background:'#1f2121',display:'flex',justifyContent:'center',width:'78%',fontSize:'19px',color:'rgb(219, 224, 225)',fontWeight:'bold',padding:'20px',border:'none',borderRadius:'10px',marginBottom:'10px',cursor:'pointer'}}  id="submit" onClick={handleSubmit}>
  <span id="button-text">
    {isLoading ? <div className="spinner" id="spinner"></div> : "Subscribe"}
  </span>
</button>
</Box>
<Modal
        open={modalView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {
            modalContent ? 
            <Box sx={{display:'flex',flexDirection:'column'}}>
                 <Box sx={{textAlign:'center'}}>
                  <FcApproval size={50}/>
                 </Box>
                 <Box sx={{fontSize:'17px',fontWeight:'bold',marginTop:'10px'}}>Payment made successfully🌟🌟🌟</Box>
            </Box> :  <Box sx={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
          <Box>
            <Box sx={{textAlign:'center',fontSize:'17px',fontWeight:'bold'}}>Please wait, Payment processing 
            </Box>
          </Box>
          <Box sx={{marginTop:'15px',display:'flex',justifyContent:'center'}}>
            <CircularProgress size={30} sx={{color:'rgb(108, 46, 255)',fontSize:'10px'}}/>
          </Box>
         </Box>
          }
        </Box>
      </Modal>
    </> 
    )
}

export default CheckoutForm;