import { Bolt, Search } from '@mui/icons-material';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import Press from './press';
import Spark from './spark';

const Help = () => {
  const navigate = useNavigate(); 

  
  const paragraphs = [
    "Manage your profile efficiently and make the most of your experience.",
    "Welcome to Sparksinger! Here's how you can get started.",
    "Discover the perks of being a PRE member and enjoy exclusive benefits.",
    "Learn how to send and receive gifts through our platform.",
    "Manage your profile efficiently and make the most of your experience.",
    "Manage your Sparksinger subscription with ease.",
    "Get creative with our Style Studio and personalize your experience.",
    "Stay safe on the app with our reporting tools and legal information.",
    "Troubleshoot technical issues effectively with our resources.",
  ];

  // Groups of headings
  const groups = [
    ["LATEST FEATURES AND UPDATES", "HOW TO USE SPARKSINGER - INTRODUCTION", "PERKS OF BEING PRE - Find out more about PRE here"],
    ["GIFTING", "PROFILE MANAGEMENT", "HOW TO MANAGE A SPARKSINGER SUBSCRIPTION"],
    ["SAFETY ON THE APP, REPORTING, COPYRIGHT, LEGAL, ETC.", "TECHNICAL ISSUES"],
  ];

  const handleParagraphClick = (index) => {
    const paragraphIndex = index; 
    navigate('/paragraph', { state: { content: paragraphs[paragraphIndex] } });
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          padding: 5,
          background: 'linear-gradient(to right, #F76720, #1B1BDB)',
        }}
      >
        <Box
          sx={{
            fontSize: 20,
            cursor: 'pointer',
            marginLeft: 120,
            textAlign: 'right',
            color: 'white',
            '&:hover': {
              color: 'red',
            },
          }}
        >
          Submit your feedback
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: "center" }}>
          <TextField
            variant="outlined"
            placeholder="Search..."
            fullWidth
            sx={{ marginRight: 1, backgroundColor: "white", borderRadius: 4, width: "400px" }}
          />
          <IconButton type="submit" color="primary">
            <Search />
          </IconButton>
        </Box>

        <Typography
          variant="h4"
          sx={{
            textAlign: 'center',
            marginTop: 2,
            fontWeight: 'bold',
            color: 'white',
          }}
        >
          Alone it's music
          <br />
          Together it's magic
        </Typography>
      </Box>

      {groups.map((group, groupIndex) => (
        <Box
          key={groupIndex}
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: 2,
            padding: 3,
          }}
        >
          {group.map((text, index) => (
            <Box
              key={index}
              onClick={() => handleParagraphClick(index + (groupIndex * group.length))} 
              sx={{
                width: 400,
                height: 100,
                border: '2px solid #6C3FEA',
                backgroundColor: 'white',
                borderRadius: '8px',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 2,
                '&:hover': {
                  backgroundColor: '#6C3FEA',
                  color: 'white',
                  cursor: 'pointer',
                },
              }}
            >
              <Typography
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  padding: '0 10px',
                }}
              >
                {text}
              </Typography>
            </Box>
          ))}
        </Box>
      ))}
      <Box><Spark/></Box>
    </Box>
  );
};

export default Help;
