import { Box, Divider, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import Spark from './spark'

const Careers = () => {
  return (
  
    <Box sx={{ padding: 5, backgroundColor: '#F7F7F7', borderRadius: 2 }}>
      <Typography variant="h4" sx={{ marginBottom: 3, fontWeight: 'bold', textAlign: 'center' }}>
        COPYRIGHT AND INTELLECTUAL PROPERTY INFRINGEMENT POLICY
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        (updated October 1, 2020)
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        To protect the rights of copyright holders, it is Spark Singer’s policy to respond to written notices of alleged infringement that comply with the Digital Millennium Copyright Act ("DMCA").
      </Typography>

      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        RESPECT OF THIRD-PARTY RIGHTS
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        Spark Singer respects the intellectual property of others and takes the protection of intellectual property very seriously, and we ask our users to do the same. Infringing activity will not be tolerated on or through the Service.
      </Typography>

      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        REPEAT INFRINGER POLICY
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        Spark Singer’s intellectual property policy is to (a) remove material that Spark Singer believes in good faith, upon notice from an intellectual property owner or their agent, is infringing the intellectual property of a third party by being made available through the Service, and (b) remove any Content posted to the Service by “repeat infringers.” Spark Singer considers a “repeat infringer” to be any user that has uploaded Content to the Service and for whom Spark Singer has received more than two takedown notices compliant with the provisions of 17 U.S.C. § 512(c) with respect to such Content. Spark Singer has discretion to terminate the account of any user of the Service after receipt of a single notification of claimed infringement or upon Spark Singer’s own determination.
      </Typography>

      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        PROCEDURE FOR REPORTING CLAIMED INFRINGEMENT
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        If You believe that any Content made available on or through the Service has been used or exploited in a manner that infringes an intellectual property right You own or control, promptly send a “Notification of Claimed Infringement” containing the following information to the Designated Agent identified below. Your communication must include substantially the following:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="A physical or electronic signature of a person authorized to act on behalf of the owner of the work(s) that has/have been allegedly infringed." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Identification of the works or material claimed to have been infringed, or, if multiple works at a single online site are covered by a single notification, a representative list of such works at that site." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Identification of the specific material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient for Spark Singer to locate the material." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Information reasonably sufficient to permit Spark Singer to contact You, such as an address, telephone number, and, if available, an electronic mail address at which the complaining party may be contacted." />
        </ListItem>
        <ListItem>
          <ListItemText primary="A statement that You have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law." />
        </ListItem>
        <ListItem>
          <ListItemText primary="A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed." />
        </ListItem>
      </List>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        You should consult with Your lawyer and/or see 17 U.S.C. § 512 of the DMCA to confirm Your obligations to provide a valid notice of claimed infringement.
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        A copy of Your Notice of Claimed Infringement will be sent to the person who uploaded the material addressed in the notice, who may submit a counter notification, as described below, that could result in restoring material removed in response to Your notice.
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        For a Notice of Claimed Infringement involving intellectual property claims other than copyright, a statement made under penalty of perjury is preferred but not required.
      </Typography>

      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        COPYRIGHT CONTACT INFORMATION
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        Send written notices for claimed infringement by email or by postal mail to:
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        Spark Singer, Inc.<br />
        Attention: Copyright Agent<br />
        [Address Placeholder]<br />
        <a href="mailto:copyright@sparksinger.com">copyright@sparksinger.com</a>
      </Typography>

      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        COUNTER-NOTIFICATION (COPYRIGHTS ONLY)
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        If You receive a notification from Spark Singer that material made available by You on or through the Service has been the subject of a Notification of Claimed Infringement, You will have the right to provide Spark Singer with what is called a “Counter Notification.” To be effective, a Counter Notification must be in writing, provided to Spark Singer’s Copyright Agent to the same email or postal address identified above for reporting copyright infringement. Your Counter Notification should include substantially the following information:
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="Your physical or electronic signature." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled." />
        </ListItem>
        <ListItem>
          <ListItemText primary="A statement, under penalty of perjury, that You have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Your name, physical address, and telephone number." />
        </ListItem>
        <ListItem>
          <ListItemText primary="A statement that You consent to the jurisdiction of a United States District Court for the judicial district in which Your address is located or, if your address is outside of the United States, for any judicial district in which Spark Singer may be found, and that You will accept service of process from the person who provided a notice in compliance with Spark Singer’s Procedure for Reporting Claimed Infringement as described above." />
        </ListItem>
      </List>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        If You are submitting a Counter Notification, You should consult a lawyer or see 17 U.S.C. § 512 of the DMCA to confirm Your obligations to provide a valid counter notification under the DMCA.
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        If You submit a Counter Notification, a copy of the counter notification, including Your name and contact information, will be sent to the copyright owner or person who provided the Notification of Claimed Infringement.
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        Spark Singer reserves the right to seek damages from any party that submits a notification of claimed infringement or counter notification in violation of the law.
      </Typography>
      <Box><Spark/></Box>
    </Box>




  )
}

export default Careers