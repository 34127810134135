import { Container, Box, Skeleton, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { url } from "../../../config/config";
import coverimage from '../../../assest/images/MUSIC.png'
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FavoriteIcon from '@mui/icons-material/Favorite';
import RedeemIcon from '@mui/icons-material/Redeem';

const ExploreView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState([])
  const [pagination, setPagination] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      console.log(scrollTop + windowHeight)
      console.log(documentHeight)
      if (Math.round(scrollTop + windowHeight) >= documentHeight - 1) {
        setPagination(prevPagination => prevPagination + 1)
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const GetExplore = async () => {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      try {
        const userTokanValue = localStorage.getItem('token');
        const res = userTokanValue !== null ? await axios.get(
          params.id === 'Recommended for you' ?
            `${url}/api/SongCollaboration/getSongCollaborationRecommendation?page=${pagination}` :
            params.id === 'Generes ' ?
              `${url}/api/SongCollaboration/getSongCollaborationSelectedGenres?page=${pagination}` :
              `${url}/api/SongCollaboration/getSongCollaborationForiegnkey?page=${pagination}`, config) : await axios.get(
                params.id === 'Recommended for you' ?
                  `${url}/api/SongCollaboration/getSongCollaborationRecommendation?page=${pagination}` :
                  params.id === 'Generes ' ?
                    `${url}/api/SongCollaboration/getSongCollaborationSelectedGenres?page=${pagination}` :
                    `${url}/api/SongCollaboration/getSongCollaborationForiegnkey?page=${pagination}`);
        if (pagination === 0) {
          setValues(res.data.data)
          console.log(res.data.data, 'SongCollaborartion get route')
        } else {
          console.log('pagination')
          setValues((prevValues) => [...prevValues, ...res.data.data])
        }
        console.log(res.data.data)
      } catch (error) {
        console.log(error)
      }
    }
    GetExplore()
  }, [pagination])


  console.log(values, 'Song Collaboration')
  console.log(params.id, 'params.id',)

  const handleClcik = (val) => {
    navigate(`/record/${val}`)
  }
  return (
    <Box>
      <Container maxWidth='lg'>
        {
          values.length > 0  ?  <>
          <Box sx={{ textAlign: 'center', fontSize: '32px', fontWeight: '700', color: 'rgb(45, 40, 103)', marginTop: '40px', marginBottom: '40px' }}>{params.id}</Box>
          <Box sx={{ display: 'flex', flexWrap: "wrap", justifyContent: "left", whiteSpace: 'nowrap', flex: 1, textAlign: 'left' }}>
            {
              values && values.map((data, ind) => (
                <Box onClick={() => handleClcik(data._id)} sx={{ marginBottom: '15px' }}>
                  <Box sx={{ padding: '7px', paddingBottom: '2px' }}>
                    <img style={{ width: '272px', height: '272px', borderRadius: '4px' }} src={data.CoverImageURL ? data.CoverImageURL : coverimage} />
                  </Box>
  
                  <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', width: "272px", color: "rgb(45, 40, 103)", fontWeight: 700, textTransform: "capitalize", fontSize: "15px", textAlign: 'left', padding: '5px', paddingBottom: '2px', whiteSpace: 'nowrap' }}>
                    {data === 0 ? 'short-sochege-tumhe-pyar-kre-ke-nhi' : `${data?.karaokes && data?.karaokes[0]?.Name}`}
                  </Box>
                  {
                    data.users && data.users.map((userData, userDataInd) => (
                      <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: "#8D8EA6", width: "272px", fontSize: "14px", textAlign: 'left', paddingLeft: '5px', paddingBottom: '2px', whiteSpace: 'nowrap', fontWeight: 400 }}>
                        {data === 0 ? 'ddddd' : `${userData.Name}`}
                      </Box>
                    ))
                  }
                   
                  <Box sx={{ display: 'flex', alignContent: 'left', justifyContent: 'flex-start' }}>
                    <Box sx={{ display: "flex", justifyContent: "space-evenly", color: "#AAADB4", fontSize: "10px", gap: 1 }}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
  
                        <Box><PlayArrowIcon fontSize='small' /></Box>
                        <Box>{data.NoOfPlay}</Box>
                      </Box>.
                      <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                        <Box><FavoriteIcon fontSize='small' /></Box>
                        <Box>{data.NoOfLikes}</Box>
                      </Box>.
                      <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                        <Box><RedeemIcon fontSize='small' /></Box>
                        <Box>{data.NoOfGifts}</Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                
              ))
            }
            
          
          </Box>
          </> :  
          <Box sx={{ display: 'flex', gap: 4 }}>
          
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  
                  <Stack>
                  <Skeleton sx={{  marginTop: '40px', marginBottom: '0px',width: '272px', height: '300px', borderRadius: '4px'  }} animation='wave'  />
                  <Skeleton animation='wave'/>
                  <Skeleton animation='wave'/>
                  <Skeleton animation='wave'/>
                  </Stack>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  
                  <Stack>
                  <Skeleton sx={{  marginTop: '40px', marginBottom: '0px',width: '272px', height: '300px', borderRadius: '4px'  }} animation='wave'  />
                  <Skeleton animation='wave'/>
                  <Skeleton animation='wave'/>
                  <Skeleton animation='wave'/>
                  </Stack>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  
                  <Stack>
                  <Skeleton sx={{  marginTop: '40px', marginBottom: '0px',width: '272px', height: '300px', borderRadius: '4px'  }} animation='wave'  />
                  <Skeleton animation='wave'/>
                  <Skeleton animation='wave'/>
                  <Skeleton animation='wave'/>
                  </Stack>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  
                  <Stack>
                  <Skeleton sx={{  marginTop: '40px', marginBottom: '0px',width: '272px', height: '300px', borderRadius: '4px'  }} animation='wave'  />
                  <Skeleton animation='wave'/>
                  <Skeleton animation='wave'/>
                  <Skeleton animation='wave'/>
                  </Stack>
                </Box>
                </Box> 
                
        }
     
      </Container>
    </Box>
  )
}

export default ExploreView;