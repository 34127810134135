import { Box, Button, Card, Container, Divider, Typography, Skeleton, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import VideocamIcon from '@mui/icons-material/Videocam';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FavoriteIcon from '@mui/icons-material/Favorite';
import RedeemIcon from '@mui/icons-material/Redeem';
import Exploredata from '../../../data/explore-data';
import { Link, useLocation } from 'react-router-dom';
import coverimage from "../../../assest/images/MUSIC.png"
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { exploreScrool } from '../../../features/addlyric/addLyric';
import { useDispatch } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { url } from '../../../config/config';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import defaultImage from '../guns.jpg'
import CoverImageURl from '../../../assest/images/images.jpg';
import SyncLoader from 'react-spinners/SyncLoader';
import FadeLoader from 'react-spinners/FadeLoader';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { IoCaretBack } from "react-icons/io5";
import { IoCaretForward } from "react-icons/io5";
import { IoIosVideocam } from "react-icons/io";
import { PiDotsThreeVertical } from "react-icons/pi";
import BasicMenu from '../../../components/basic-menu2';
import './explore.css';
const Videocard = () => {
  const navigate = useNavigate()
  const scrollValue = useSelector((state) => state)
  const scroolRef = useRef(0)
  const dispatch = useDispatch()
  let location = useLocation();
  let [color, setColor] = useState("gray");
  const videoScroll = useRef();

  console.log(localStorage.getItem('token'),'tokanValuessssss')

  useEffect(() => {
    window.scrollTo(0, (scrollValue.Upload.scrollValue * 360))
    // return () => {
    //   dispatch(exploreScrool(0))
    //   };
  }, [scrollValue])
  const [explore, getexplore] = useState([])
  const [recommended, setRecommended] = useState([])
  const [genors, setGenors] = useState([])
  const [pagination, setPagination] = useState(0);
  const [values, setValues] = useState([])
  const exploreScrollRef = useRef()
  const recommendedScrollRef = useRef()
  const GenereScrollRef= useRef()
  const MomentsScrollRef =useRef()
  const TrendingScrollref=useRef()
  const WhatsScrollref=useRef()
  const GroupsScrollref=useRef()
  const KoreaScrollref=useRef()
  const IndiaScrollref=useRef()
  
  
  useEffect(() => {
    const handleForward = () => {
      const scrollLeft = window.scrollX;
      const windowWidth = window.innerWidth;
      const documentWidth = document.documentElement.scrollWidth;
      if (scrollLeft + windowWidth >= documentWidth) {
        console.log('EverOne Incremant')
        setPagination(prevPagination => prevPagination + 1)
      }
    };

    window.addEventListener('click', handleForward);

    return () => {
      window.removeEventListener('click', handleForward);
    };
  }, []);
  console.log(values)
  console.log(pagination)
  useEffect(() => {
    console.log('vijay')
    const userSongCollaboration = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }

        const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationRecommendation?page=${pagination}`, config) 
        console.log(res.data.data)
        if (pagination === 0) {
          getexplore(res.data.data)
          console.log(res.data.data,"recommendation..........")
        } else {
          console.log('pagination')
          getexplore((prevValues) => [...prevValues, ...res.data.data])
        }
        console.log(res.data.data)
      } catch (error) {
        console.log(error)
      }
    }
    userSongCollaboration()

   
  }, [])
  

  useEffect(()=>{
    const userSongCollaborationByGenres = async () => {
      const userTokanValue = localStorage.getItem('token');
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = userTokanValue !== null ?  await axios.get(`${url}/api/SongCollaboration/getSongCollaborationSelectedGenres?page=${pagination}`, config) : await axios.get(`${url}/api/SongCollaboration/getSongCollaborationOtherSelectedGenres?page=${pagination}`)
        setGenors(res.data.data)
        console.log("uyfguiuytyuideqhiewhiuwhfwhewhfh")
      } catch (error) {
        console.log(error)
      }
    }
    userSongCollaborationByGenres()
  },[])
  
  useEffect(()=>{
    const GetExplore=async()=>{
 try {
       const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationForiegnkey`);
        console.log(res.data,'valuessssss')
       setRecommended(res.data.data)
   } catch (error) {
      console.log(error)
   }
    }
    GetExplore()
  },[])
  
  console.log(recommended,'recommendedss')

  //  useEffect(()=>{

  //    const GetExplore=async()=>{

  // try {
  //       const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationForiegnkey`);
  //       getexplore(res.data.data)
  //   } catch (error) {

  //   }
  //   //    
  //    }
  //    GetExplore()

  //  },[])
  //  console.log(explore)
  //  useEffect(()=>{

  //    const GetExplore=async()=>{

  // try {
  //       const res = await axios.get(`http://localhost:7000/get`);

  //       console.log(res)
  //   } catch (error) {

  //   }
  //   //    
  //    }
  //    GetExplore()

  //  },[])

  const handleExplore = (val) => {
    navigate(`/listen/${val}`)
  }

  const scrollAmount = 400;

  const handleScroll = (direction) => {
    videoScroll.current.scrollLeft += direction * scrollAmount;
  }
  
  const handleBackward = (val) => {
    if(val === 'explore'){
        exploreScrollRef.current.scrollLeft += -1 * scrollAmount
    }
    if(val === 'recommended'){
      recommendedScrollRef.current.scrollLeft += -1 * scrollAmount
    }
    if(val === 'Genera'){
      GenereScrollRef.current.scrollLeft += -1 * scrollAmount
    }  
    if(val === 'Moments'){
      MomentsScrollRef.current.scrollLeft += -1 * scrollAmount
    } 
    if(val === 'Trending Recordings'){
      TrendingScrollref.current.scrollLeft += -1 * scrollAmount
    } 
    if(val === 'Whats hots'){
      WhatsScrollref.current.scrollLeft += -1 * scrollAmount
    }
    if(val === 'Groups'){
      GroupsScrollref.current.scrollLeft += -1 * scrollAmount
    }
    if(val === 'Korea'){
      KoreaScrollref.current.scrollLeft += -1 * scrollAmount
    }
    if(val === 'India'){
      IndiaScrollref.current.scrollLeft += -1 * scrollAmount
    }
    
  }
  const handleForward = (val) => {
    console.log(val)
    if(val === 'explore'){
      exploreScrollRef.current.scrollLeft += 1 * scrollAmount
    }
    if(val === 'recommended'){
      recommendedScrollRef.current.scrollLeft += 1 * scrollAmount
    }
    if(val === 'Genera'){
      GenereScrollRef.current.scrollLeft += 1 * scrollAmount
    }
    if(val === 'Moments'){
      MomentsScrollRef.current.scrollLeft += 1 * scrollAmount
    } 
    if(val === 'Trending Recordings'){
      TrendingScrollref.current.scrollLeft += 1 * scrollAmount
    } 
    if(val === 'Whats hots'){
      WhatsScrollref.current.scrollLeft += 1 * scrollAmount
    } 
    if(val === 'Groups'){
      GroupsScrollref.current.scrollLeft += 1 * scrollAmount
    } 
    if(val === 'Korea'){
      KoreaScrollref.current.scrollLeft += 1 * scrollAmount
    } 
    if(val === 'India'){
      IndiaScrollref.current.scrollLeft += 1 * scrollAmount
    } 
  }

  const handleSongCollborationNavigate = (val) => {
    console.log(val,'clikedValuessss')
    navigate(`/record/${val._id}`)
  }

  return (

    <Box >
      <Container maxWidth="xl" >
        
         {/* Explore */}
        <Box ref={scroolRef} sx={{ display: 'flex' }}>
          <Box onClick={() => handleBackward('explore')} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretBack size={40} color='rgb(68 114 196)' />
          </Box>
          <Card sx={{ margin: 2, boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
            <Box >
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box>{<ElectricBoltIcon style={{ color: 'red', fontSize: '30px' }} />}</Box>
                  <Box sx={{ color: "#662867", fontWeight: 900, fontSize: "20px" }}>Explore</Box>
                </Box>
                <Box sx={{ color: "blue", fontWeight: 1000, cursor: 'pointer' }} onClick={() => handleExplore('Explore')}>View All</Box>
              </Box>

            </Box>
            <Divider />

            <Box className='explore-card' ref={exploreScrollRef} sx={{ display: "flex", flexDirection: "row", overflowX: "scroll", padding: '5px', paddingTop: '10px' }}>
              {
                recommended.length !== 0 ? <>
                  {
                    recommended.length && recommended.map((data, index) => (
                      <Box>
                      <Button onClick={() => {handleSongCollborationNavigate(data)}} style={{ textDecoration: "none" }}>
                          {
                            data.karaokes && data.karaokes.map((val, ind) => (
                              <>
                                <Box sx={{ position: "relative", textAlign: "center", width: "180px", height: '279px', backgroundImage: `url(${data.CoverImageURL?data.CoverImageURL:coverimage})`, borderRadius: '5px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    {
                                      data.PostType === "Video" || "Animation" ? <Box sx={{ display: 'flex', justifyContent: 'end', padding: '3px',alignItems:'center'}}>
                                        <IoIosVideocam style={{ background: 'rgba(128, 128, 128, 0.2)', padding: 1, borderRadius: '5px', paddingTop: 0, paddingBottom: 0 }} fill='white' size={20} />
                                        <Box onClick={(e) => {
                                          e.stopPropagation();
                                        }} sx={{marginTop:1}}
                                        >
                                          {/* <PiDotsThreeVertical style={{paddingTop:0,paddingBottom:0}} fill='white' size={20} /> */}
                                          <BasicMenu id={data.users && data.users[0]?._id} value={val && val._id} />
                                        </Box>
                                      </Box> : ''
                                    }
                                    <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                      <Box sx={{ color: 'rgb(255, 255, 255)', fontWeight: 900, textTransform: "none", fontSize: "14px", textAlign: 'left', paddingLeft: '3px', paddingTop: 0, paddingBottom: 0 }}>
                                        {data === 0 ? 'short-sochege-tumhe-pyar-kre-ke-nhi' : `${val.Name}`}
                                      </Box>
                                      {
                                        data.users && data.users.map((userData, userDataInd) => (
                                          <Box sx={{ display: 'flex', gap: .3, padding: '5px', paddingTop: 0, paddingBottom: 0 }}>
                                            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: "rgb(192, 204, 216)", fontSize: "14px", fontWeight: 400, whiteSpace: 'nowrap', textTransform: 'none' }}>
                                              {data === 0 ? 'ddddd' : `${userData.Name}`}
                                            </Box>
                                            {
                                              data.CollaborationTypeID === 'Duet' ? <Box sx={{ color: 'rgb(192, 204, 216)', fontSize: '12px', fontWeight: 400 }}>+ 1</Box> : ''
                                            }
                                          </Box>

                                        ))
                                      }
                                      <Box sx={{ display: 'flex', alignContent: 'left', justifyContent: 'flex-start', paddingBottom: '5px' }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-evenly", color: "#AAADB4", fontSize: "10px", gap: 1 }}>
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>

                                            <Box><PlayArrowIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfPlay}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><FavoriteIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfLikes}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><RedeemIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfGifts}</Box>
                                          </Box>

                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ))
                          }

                        </Button>
                      </Box>
                    ))
                  }
                </> : <Box sx={{ display: 'flex', gap: 4 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                </Box>
              }
            </Box>
          </Card>
          <Box onClick={() => {handleForward('explore')}} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretForward size={40} color='rgb(68 114 196)' />
          </Box>
        </Box>

       {/* Recommendation */}
        <Box ref={scroolRef} sx={{ display: 'flex' }}>
          <Box onClick={() => handleBackward('recommended')} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretBack size={40} color='rgb(68 114 196)' />
          </Box>
          <Card sx={{ margin: 2, boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
            <Box >
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box>{<ElectricBoltIcon style={{ color: 'red', fontSize: '30px' }} />}</Box>
                  <Box sx={{ color: "#662867", fontWeight: 900, fontSize: "20px" }}>Recommented for you </Box>
                </Box>
                <Box sx={{ color: "blue", fontWeight: 1000, cursor: 'pointer' }} onClick={() => handleExplore('Recommended for you')}>View All</Box>
              </Box>

            </Box>
            <Divider />

            <Box className='explore-card' ref={recommendedScrollRef} sx={{ display: "flex", flexDirection: "row", overflowX: "scroll", padding: '5px', paddingTop: '10px' }}>
              {
                explore.length !== 0 ? <>
                  {
                    explore.length && explore.map((data, index) => (
                      <Box>
                        <Button onClick={() => {
                          navigate(`/record/${data._id}`, {
                            state: { param1: explore, param2: index }
                          })
                        }} style={{ textDecoration: "none" }}>
                          {
                            data.karaokes && data.karaokes.map((val, ind) => (
                              <>
                                <Box sx={{ position: "relative", textAlign: "center", width: "180px", height: '279px', backgroundImage: `url(${data.CoverImageURL?data.CoverImageURL:coverimage})`, borderRadius: '5px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    {
                                      data.PostType === "Video" || "Animation" ? <Box sx={{ display: 'flex', justifyContent: 'end', padding: '5px' }}>
                                        <IoIosVideocam style={{ background: 'rgba(128, 128, 128, 0.2)', padding: 1, borderRadius: '5px', paddingTop: 0, paddingBottom: 0 }} fill='white' size={20} />
                                        <Box onClick={(e) => {
                                          e.stopPropagation();
                                        }} sx={{ Color: "red" }}
                                        >
                                          {/* <PiDotsThreeVertical style={{paddingTop:0,paddingBottom:0}} fill='white' size={20} /> */}
                                          <BasicMenu id={data.users && data.users[0]?._id} value={val && val._id} />
                                        </Box>
                                      </Box> : ''
                                    }
                                    <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                      <Box sx={{ color: 'rgb(255, 255, 255)', fontWeight: 900, textTransform: "none", fontSize: "14px", textAlign: 'left', paddingLeft: '3px', paddingTop: 0, paddingBottom: 0 }}>
                                        {data === 0 ? 'short-sochege-tumhe-pyar-kre-ke-nhi' : `${val.Name}`}
                                      </Box>
                                      {
                                        data.users && data.users.map((userData, userDataInd) => (
                                          <Box sx={{ display: 'flex', gap: .3, padding: '5px', paddingTop: 0, paddingBottom: 0 }}>
                                            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: "rgb(192, 204, 216)", fontSize: "14px", fontWeight: 400, whiteSpace: 'nowrap', textTransform: 'none' }}>
                                              {data === 0 ? 'ddddd' : `${userData.Name}`}
                                            </Box>
                                            {
                                              data.CollaborationTypeID === 'Duet' ? <Box sx={{ color: 'rgb(192, 204, 216)', fontSize: '12px', fontWeight: 400 }}>+ 1</Box> : ''
                                            }
                                          </Box>

                                        ))
                                      }
                                      <Box sx={{ display: 'flex', alignContent: 'left', justifyContent: 'flex-start', paddingBottom: '5px' }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-evenly", color: "#AAADB4", fontSize: "10px", gap: 1 }}>
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>

                                            <Box><PlayArrowIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfPlay}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><FavoriteIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfLikes}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><RedeemIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfGifts}</Box>
                                          </Box>

                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ))
                          }

                        </Button>
                      </Box>
                    ))
                  }
                </> : <Box sx={{ display: 'flex', gap: 4 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                </Box>
              }
            </Box>
          </Card>
          <Box onClick={() => {handleForward('recommended')}} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretForward size={40} color='rgb(68 114 196)' />
          </Box>
        </Box>

        {/* Genere */}
        <Box ref={scroolRef} sx={{ display: 'flex' }}>
          <Box onClick={() => handleBackward('Genera')} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretBack size={40} color='rgb(68 114 196)' />
          </Box>
          <Card sx={{ margin: 2, boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
            <Box >
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box>{<ElectricBoltIcon style={{ color: 'red', fontSize: '30px' }} />}</Box>
                  <Box sx={{ color: "#662867", fontWeight: 900, fontSize: "20px" }}>Genere</Box>
                </Box>
                <Box sx={{ color: "blue", fontWeight: 1000, cursor: 'pointer' }} onClick={() => handleExplore('Generes')}>View All</Box>
              </Box>

            </Box>
            <Divider />

            <Box className='explore-card' ref={GenereScrollRef} sx={{ display: "flex", flexDirection: "row", overflowX: "scroll", padding: '5px', paddingTop: '10px' }}>
              {
                genors.length !== 0 ? <>
                  {
                    genors.length && genors.map((data, index) => (
                      <Box>
                        <Button onClick={() => {
                          navigate(`/record/${data._id}`, {
                            state: { param1: genors, param2: index }
                          })
                        }} style={{ textDecoration: "none" }}>
                          {
                            data.karaokes && data.karaokes.map((val, ind) => (
                              <>
                                <Box sx={{ position: "relative", textAlign: "center", width: "180px", height: '279px', backgroundImage: `url(${data.CoverImageURL?data.CoverImageURL:coverimage})`, borderRadius: '5px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    {
                                      data.PostType === "Video" || "Animation" ? <Box sx={{ display: 'flex', justifyContent: 'end', padding: '5px' }}>
                                        <IoIosVideocam style={{ background: 'rgba(128, 128, 128, 0.2)', padding: 1, borderRadius: '5px', paddingTop: 0, paddingBottom: 0 }} fill='white' size={20} />
                                        <Box onClick={(e) => {
                                          e.stopPropagation();
                                        }} sx={{ Color: "red" }}
                                        >
                                          {/* <PiDotsThreeVertical style={{paddingTop:0,paddingBottom:0}} fill='white' size={20} /> */}
                                          <BasicMenu id={data.users && data.users[0]?._id} value={val && val._id} />
                                        </Box>
                                      </Box> : ''
                                    }
                                    <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                      <Box sx={{ color: 'rgb(255, 255, 255)', fontWeight: 900, textTransform: "none", fontSize: "14px", textAlign: 'left', paddingLeft: '3px', paddingTop: 0, paddingBottom: 0 }}>
                                        {data === 0 ? 'short-sochege-tumhe-pyar-kre-ke-nhi' : `${val.Name}`}
                                      </Box>
                                      {
                                        data.users && data.users.map((userData, userDataInd) => (
                                          <Box sx={{ display: 'flex', gap: .3, padding: '5px', paddingTop: 0, paddingBottom: 0 }}>
                                            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: "rgb(192, 204, 216)", fontSize: "14px", fontWeight: 400, whiteSpace: 'nowrap', textTransform: 'none' }}>
                                              {data === 0 ? 'ddddd' : `${userData.Name}`}
                                            </Box>
                                            {
                                              data.CollaborationTypeID === 'Duet' ? <Box sx={{ color: 'rgb(192, 204, 216)', fontSize: '12px', fontWeight: 400 }}>+ 1</Box> : ''
                                            }
                                          </Box>

                                        ))
                                      }
                                      <Box sx={{ display: 'flex', alignContent: 'left', justifyContent: 'flex-start', paddingBottom: '5px' }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-evenly", color: "#AAADB4", fontSize: "10px", gap: 1 }}>
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>

                                            <Box><PlayArrowIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfPlay}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><FavoriteIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfLikes}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><RedeemIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfGifts}</Box>
                                          </Box>

                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ))
                          }

                        </Button>
                      </Box>
                    ))
                  }
                </> : <Box sx={{ display: 'flex', gap: 4 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                </Box>
              }
            </Box>
          </Card>
          <Box onClick={() => handleForward('Genera')} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretForward size={40} color='rgb(68 114 196)' />
          </Box>
        </Box>
        
        <Box ref={scroolRef} sx={{ display: 'flex' }}>
          <Box onClick={() => handleBackward('Moments')} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretBack size={40} color='rgb(68 114 196)' />
          </Box>
          <Card sx={{ margin: 2, boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
            <Box >
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box>{<ElectricBoltIcon style={{ color: 'red', fontSize: '30px' }} />}</Box>
                  <Box sx={{ color: "#662867", fontWeight: 900, fontSize: "20px" }}>Moments</Box>
                </Box>
                <Box sx={{ color: "blue", fontWeight: 1000, cursor: 'pointer' }} onClick={() => handleExplore('Explore')}>View All</Box>
              </Box>

            </Box>
            <Divider />

            <Box className='explore-card' ref={MomentsScrollRef} sx={{ display: "flex", flexDirection: "row", overflowX: "scroll", padding: '5px', paddingTop: '10px' }}>
              {
                recommended.length !== 0 ? <>
                  {
                    recommended.length && recommended.map((data, index) => (
                      <Box>
                        <Button onClick={() => {
                          navigate(`/record/${data._id}`, {
                            state: { param1: explore, param2: index }
                          })
                        }} style={{ textDecoration: "none" }}>
                          {
                            data.karaokes && data.karaokes.map((val, ind) => (
                              <>
                                <Box sx={{ position: "relative", textAlign: "center", width: "180px", height: '279px', backgroundImage: `url(${data.CoverImageURL?data.CoverImageURL:coverimage})`, borderRadius: '5px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    {
                                      data.PostType === "Video" || "Animation" ? <Box sx={{ display: 'flex', justifyContent: 'end', padding: '3px',alignItems:'center'}}>
                                        <IoIosVideocam style={{ background: 'rgba(128, 128, 128, 0.2)', padding: 1, borderRadius: '5px', paddingTop: 0, paddingBottom: 0 }} fill='white' size={20} />
                                        <Box onClick={(e) => {
                                          e.stopPropagation();
                                        }} sx={{marginTop:1}}
                                        >
                                          {/* <PiDotsThreeVertical style={{paddingTop:0,paddingBottom:0}} fill='white' size={20} /> */}
                                          <BasicMenu id={data.users && data.users[0]?._id} value={val && val._id} />
                                        </Box>
                                      </Box> : ''
                                    }
                                    <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                      <Box sx={{ color: 'rgb(255, 255, 255)', fontWeight: 900, textTransform: "none", fontSize: "14px", textAlign: 'left', paddingLeft: '3px', paddingTop: 0, paddingBottom: 0 }}>
                                        {data === 0 ? 'short-sochege-tumhe-pyar-kre-ke-nhi' : `${val.Name}`}
                                      </Box>
                                      {
                                        data.users && data.users.map((userData, userDataInd) => (
                                          <Box sx={{ display: 'flex', gap: .3, padding: '5px', paddingTop: 0, paddingBottom: 0 }}>
                                            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: "rgb(192, 204, 216)", fontSize: "14px", fontWeight: 400, whiteSpace: 'nowrap', textTransform: 'none' }}>
                                              {data === 0 ? 'ddddd' : `${userData.Name}`}
                                            </Box>
                                            {
                                              data.CollaborationTypeID === 'Duet' ? <Box sx={{ color: 'rgb(192, 204, 216)', fontSize: '12px', fontWeight: 400 }}>+ 1</Box> : ''
                                            }
                                          </Box>

                                        ))
                                      }
                                      <Box sx={{ display: 'flex', alignContent: 'left', justifyContent: 'flex-start', paddingBottom: '5px' }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-evenly", color: "#AAADB4", fontSize: "10px", gap: 1 }}>
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>

                                            <Box><PlayArrowIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfPlay}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><FavoriteIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfLikes}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><RedeemIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfGifts}</Box>
                                          </Box>

                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ))
                          }

                        </Button>
                      </Box>
                    ))
                  }
                </> : <Box sx={{ display: 'flex', gap: 4 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                </Box>
              }
            </Box>
          </Card>
          <Box onClick={() => {handleForward('Moments')}} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretForward size={40} color='rgb(68 114 196)' />
          </Box>
        </Box>
        <Box ref={scroolRef} sx={{ display: 'flex' }}>
          <Box onClick={() => handleBackward('Trending Recordings')} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretBack size={40} color='rgb(68 114 196)' />
          </Box>
          <Card sx={{ margin: 2, boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
            <Box >
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box>{<ElectricBoltIcon style={{ color: 'red', fontSize: '30px' }} />}</Box>
                  <Box sx={{ color: "#662867", fontWeight: 900, fontSize: "20px" }}>Trending Recordings</Box>
                </Box>
                <Box sx={{ color: "blue", fontWeight: 1000, cursor: 'pointer' }} onClick={() => handleExplore('Explore')}>View All</Box>
              </Box>

            </Box>
            <Divider />

            <Box className='explore-card' ref={TrendingScrollref} sx={{ display: "flex", flexDirection: "row", overflowX: "scroll", padding: '5px', paddingTop: '10px' }}>
              {
                recommended.length !== 0 ? <>
                  {
                    recommended.length && recommended.map((data, index) => (
                      <Box>
                        <Button onClick={() => {
                          navigate(`/record/${data._id}`, {
                            state: { param1: explore, param2: index }
                          })
                        }} style={{ textDecoration: "none" }}>
                          {
                            data.karaokes && data.karaokes.map((val, ind) => (
                              <>
                                <Box sx={{ position: "relative", textAlign: "center", width: "180px", height: '279px', backgroundImage: `url(${data.CoverImageURL?data.CoverImageURL:coverimage})`, borderRadius: '5px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    {
                                      data.PostType === "Video" || "Animation" ? <Box sx={{ display: 'flex', justifyContent: 'end', padding: '3px',alignItems:'center'}}>
                                        <IoIosVideocam style={{ background: 'rgba(128, 128, 128, 0.2)', padding: 1, borderRadius: '5px', paddingTop: 0, paddingBottom: 0 }} fill='white' size={20} />
                                        <Box onClick={(e) => {
                                          e.stopPropagation();
                                        }} sx={{marginTop:1}}
                                        >
                                          {/* <PiDotsThreeVertical style={{paddingTop:0,paddingBottom:0}} fill='white' size={20} /> */}
                                          <BasicMenu id={data.users && data.users[0]?._id} value={val && val._id} />
                                        </Box>
                                      </Box> : ''
                                    }
                                    <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                      <Box sx={{ color: 'rgb(255, 255, 255)', fontWeight: 900, textTransform: "none", fontSize: "14px", textAlign: 'left', paddingLeft: '3px', paddingTop: 0, paddingBottom: 0 }}>
                                        {data === 0 ? 'short-sochege-tumhe-pyar-kre-ke-nhi' : `${val.Name}`}
                                      </Box>
                                      {
                                        data.users && data.users.map((userData, userDataInd) => (
                                          <Box sx={{ display: 'flex', gap: .3, padding: '5px', paddingTop: 0, paddingBottom: 0 }}>
                                            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: "rgb(192, 204, 216)", fontSize: "14px", fontWeight: 400, whiteSpace: 'nowrap', textTransform: 'none' }}>
                                              {data === 0 ? 'ddddd' : `${userData.Name}`}
                                            </Box>
                                            {
                                              data.CollaborationTypeID === 'Duet' ? <Box sx={{ color: 'rgb(192, 204, 216)', fontSize: '12px', fontWeight: 400 }}>+ 1</Box> : ''
                                            }
                                          </Box>

                                        ))
                                      }
                                      <Box sx={{ display: 'flex', alignContent: 'left', justifyContent: 'flex-start', paddingBottom: '5px' }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-evenly", color: "#AAADB4", fontSize: "10px", gap: 1 }}>
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>

                                            <Box><PlayArrowIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfPlay}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><FavoriteIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfLikes}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><RedeemIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfGifts}</Box>
                                          </Box>

                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ))
                          }

                        </Button>
                      </Box>
                    ))
                  }
                </> : <Box sx={{ display: 'flex', gap: 4 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                </Box>
              }
            </Box>
          </Card>
          <Box onClick={() => {handleForward('Trending Recordings')}} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretForward size={40} color='rgb(68 114 196)' />
          </Box>
        </Box>
        <Box ref={scroolRef} sx={{ display: 'flex' }}>
          <Box onClick={() => handleBackward('Whats hots')} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretBack size={40} color='rgb(68 114 196)' />
          </Box>
          <Card sx={{ margin: 2, boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
            <Box >
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box>{<ElectricBoltIcon style={{ color: 'red', fontSize: '30px' }} />}</Box>
                  <Box sx={{ color: "#662867", fontWeight: 900, fontSize: "20px" }}>Whats hot</Box>
                </Box>
                <Box sx={{ color: "blue", fontWeight: 1000, cursor: 'pointer' }} onClick={() => handleExplore('Explore')}>View All</Box>
              </Box>

            </Box>
            <Divider />

            <Box className='explore-card' ref={ WhatsScrollref} sx={{ display: "flex", flexDirection: "row", overflowX: "scroll", padding: '5px', paddingTop: '10px' }}>
              {
                recommended.length !== 0 ? <>
                  {
                    recommended.length && recommended.map((data, index) => (
                      <Box>
                        <Button onClick={() => {
                          navigate(`/record/${data._id}`, {
                            state: { param1: explore, param2: index }
                          })
                        }} style={{ textDecoration: "none" }}>
                          {
                            data.karaokes && data.karaokes.map((val, ind) => (
                              <>
                                <Box sx={{ position: "relative", textAlign: "center", width: "180px", height: '279px', backgroundImage: `url(${data.CoverImageURL?data.CoverImageURL:coverimage})`, borderRadius: '5px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    {
                                      data.PostType === "Video" || "Animation" ? <Box sx={{ display: 'flex', justifyContent: 'end', padding: '3px',alignItems:'center'}}>
                                        <IoIosVideocam style={{ background: 'rgba(128, 128, 128, 0.2)', padding: 1, borderRadius: '5px', paddingTop: 0, paddingBottom: 0 }} fill='white' size={20} />
                                        <Box onClick={(e) => {
                                          e.stopPropagation();
                                        }} sx={{marginTop:1}}
                                        >
                                          {/* <PiDotsThreeVertical style={{paddingTop:0,paddingBottom:0}} fill='white' size={20} /> */}
                                          <BasicMenu id={data.users && data.users[0]?._id} value={val && val._id} />
                                        </Box>
                                      </Box> : ''
                                    }
                                    <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                      <Box sx={{ color: 'rgb(255, 255, 255)', fontWeight: 900, textTransform: "none", fontSize: "14px", textAlign: 'left', paddingLeft: '3px', paddingTop: 0, paddingBottom: 0 }}>
                                        {data === 0 ? 'short-sochege-tumhe-pyar-kre-ke-nhi' : `${val.Name}`}
                                      </Box>
                                      {
                                        data.users && data.users.map((userData, userDataInd) => (
                                          <Box sx={{ display: 'flex', gap: .3, padding: '5px', paddingTop: 0, paddingBottom: 0 }}>
                                            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: "rgb(192, 204, 216)", fontSize: "14px", fontWeight: 400, whiteSpace: 'nowrap', textTransform: 'none' }}>
                                              {data === 0 ? 'ddddd' : `${userData.Name}`}
                                            </Box>
                                            {
                                              data.CollaborationTypeID === 'Duet' ? <Box sx={{ color: 'rgb(192, 204, 216)', fontSize: '12px', fontWeight: 400 }}>+ 1</Box> : ''
                                            }
                                          </Box>

                                        ))
                                      }
                                      <Box sx={{ display: 'flex', alignContent: 'left', justifyContent: 'flex-start', paddingBottom: '5px' }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-evenly", color: "#AAADB4", fontSize: "10px", gap: 1 }}>
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>

                                            <Box><PlayArrowIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfPlay}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><FavoriteIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfLikes}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><RedeemIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfGifts}</Box>
                                          </Box>

                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ))
                          }

                        </Button>
                      </Box>
                    ))
                  }
                </> : <Box sx={{ display: 'flex', gap: 4 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                </Box>
              }
            </Box>
          </Card>
          <Box onClick={() => {handleForward('Whats hots')}} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretForward size={40} color='rgb(68 114 196)' />
          </Box>
        </Box>
        <Box ref={scroolRef} sx={{ display: 'flex' }}>
          <Box onClick={() => handleBackward('Groups')} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretBack size={40} color='rgb(68 114 196)' />
          </Box>
          <Card sx={{ margin: 2, boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
            <Box >
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box>{<ElectricBoltIcon style={{ color: 'red', fontSize: '30px' }} />}</Box>
                  <Box sx={{ color: "#662867", fontWeight: 900, fontSize: "20px" }}>Groups</Box>
                </Box>
                <Box sx={{ color: "blue", fontWeight: 1000, cursor: 'pointer' }} onClick={() => handleExplore('Explore')}>View All</Box>
              </Box>

            </Box>
            <Divider />

            <Box className='explore-card' ref={ GroupsScrollref} sx={{ display: "flex", flexDirection: "row", overflowX: "scroll", padding: '5px', paddingTop: '10px' }}>
              {
                recommended.length !== 0 ? <>
                  {
                    recommended.length && recommended.map((data, index) => (
                      <Box>
                        <Button onClick={() => {
                          navigate(`/record/${data._id}`, {
                            state: { param1: explore, param2: index }
                          })
                        }} style={{ textDecoration: "none" }}>
                          {
                            data.karaokes && data.karaokes.map((val, ind) => (
                              <>
                                <Box sx={{ position: "relative", textAlign: "center", width: "180px", height: '279px', backgroundImage: `url(${data.CoverImageURL?data.CoverImageURL:coverimage})`, borderRadius: '5px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    {
                                      data.PostType === "Video" || "Animation" ? <Box sx={{ display: 'flex', justifyContent: 'end', padding: '3px',alignItems:'center'}}>
                                        <IoIosVideocam style={{ background: 'rgba(128, 128, 128, 0.2)', padding: 1, borderRadius: '5px', paddingTop: 0, paddingBottom: 0 }} fill='white' size={20} />
                                        <Box onClick={(e) => {
                                          e.stopPropagation();
                                        }} sx={{marginTop:1}}
                                        >
                                          {/* <PiDotsThreeVertical style={{paddingTop:0,paddingBottom:0}} fill='white' size={20} /> */}
                                          <BasicMenu id={data.users && data.users[0]?._id} value={val && val._id} />
                                        </Box>
                                      </Box> : ''
                                    }
                                    <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                      <Box sx={{ color: 'rgb(255, 255, 255)', fontWeight: 900, textTransform: "none", fontSize: "14px", textAlign: 'left', paddingLeft: '3px', paddingTop: 0, paddingBottom: 0 }}>
                                        {data === 0 ? 'short-sochege-tumhe-pyar-kre-ke-nhi' : `${val.Name}`}
                                      </Box>
                                      {
                                        data.users && data.users.map((userData, userDataInd) => (
                                          <Box sx={{ display: 'flex', gap: .3, padding: '5px', paddingTop: 0, paddingBottom: 0 }}>
                                            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: "rgb(192, 204, 216)", fontSize: "14px", fontWeight: 400, whiteSpace: 'nowrap', textTransform: 'none' }}>
                                              {data === 0 ? 'ddddd' : `${userData.Name}`}
                                            </Box>
                                            {
                                              data.CollaborationTypeID === 'Duet' ? <Box sx={{ color: 'rgb(192, 204, 216)', fontSize: '12px', fontWeight: 400 }}>+ 1</Box> : ''
                                            }
                                          </Box>

                                        ))
                                      }
                                      <Box sx={{ display: 'flex', alignContent: 'left', justifyContent: 'flex-start', paddingBottom: '5px' }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-evenly", color: "#AAADB4", fontSize: "10px", gap: 1 }}>
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>

                                            <Box><PlayArrowIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfPlay}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><FavoriteIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfLikes}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><RedeemIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfGifts}</Box>
                                          </Box>

                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ))
                          }

                        </Button>
                      </Box>
                    ))
                  }
                </> : <Box sx={{ display: 'flex', gap: 4 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                </Box>
              }
            </Box>
          </Card>
          <Box onClick={() => {handleForward('Groups')}} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretForward size={40} color='rgb(68 114 196)' />
          </Box>
        </Box>
        <Box ref={scroolRef} sx={{ display: 'flex' }}>
          <Box onClick={() => handleBackward('Korea')} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretBack size={40} color='rgb(68 114 196)' />
          </Box>
          <Card sx={{ margin: 2, boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
            <Box >
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box>{<ElectricBoltIcon style={{ color: 'red', fontSize: '30px' }} />}</Box>
                  <Box sx={{ color: "#662867", fontWeight: 900, fontSize: "20px" }}>Korea</Box>
                </Box>
                <Box sx={{ color: "blue", fontWeight: 1000, cursor: 'pointer' }} onClick={() => handleExplore('Explore')}>View All</Box>
              </Box>

            </Box>
            <Divider />

            <Box className='explore-card' ref={ KoreaScrollref} sx={{ display: "flex", flexDirection: "row", overflowX: "scroll", padding: '5px', paddingTop: '10px' }}>
              {
                recommended.length !== 0 ? <>
                  {
                    recommended.length && recommended.map((data, index) => (
                      <Box>
                        <Button onClick={() => {
                          navigate(`/record/${data._id}`, {
                            state: { param1: explore, param2: index }
                          })
                        }} style={{ textDecoration: "none" }}>
                          {
                            data.karaokes && data.karaokes.map((val, ind) => (
                              <>
                                <Box sx={{ position: "relative", textAlign: "center", width: "180px", height: '279px', backgroundImage: `url(${data.CoverImageURL?data.CoverImageURL:coverimage})`, borderRadius: '5px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    {
                                      data.PostType === "Video" || "Animation" ? <Box sx={{ display: 'flex', justifyContent: 'end', padding: '3px',alignItems:'center'}}>
                                        <IoIosVideocam style={{ background: 'rgba(128, 128, 128, 0.2)', padding: 1, borderRadius: '5px', paddingTop: 0, paddingBottom: 0 }} fill='white' size={20} />
                                        <Box onClick={(e) => {
                                          e.stopPropagation();
                                        }} sx={{marginTop:1}}
                                        >
                                          {/* <PiDotsThreeVertical style={{paddingTop:0,paddingBottom:0}} fill='white' size={20} /> */}
                                          <BasicMenu id={data.users && data.users[0]?._id} value={val && val._id} />
                                        </Box>
                                      </Box> : ''
                                    }
                                    <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                      <Box sx={{ color: 'rgb(255, 255, 255)', fontWeight: 900, textTransform: "none", fontSize: "14px", textAlign: 'left', paddingLeft: '3px', paddingTop: 0, paddingBottom: 0 }}>
                                        {data === 0 ? 'short-sochege-tumhe-pyar-kre-ke-nhi' : `${val.Name}`}
                                      </Box>
                                      {
                                        data.users && data.users.map((userData, userDataInd) => (
                                          <Box sx={{ display: 'flex', gap: .3, padding: '5px', paddingTop: 0, paddingBottom: 0 }}>
                                            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: "rgb(192, 204, 216)", fontSize: "14px", fontWeight: 400, whiteSpace: 'nowrap', textTransform: 'none' }}>
                                              {data === 0 ? 'ddddd' : `${userData.Name}`}
                                            </Box>
                                            {
                                              data.CollaborationTypeID === 'Duet' ? <Box sx={{ color: 'rgb(192, 204, 216)', fontSize: '12px', fontWeight: 400 }}>+ 1</Box> : ''
                                            }
                                          </Box>

                                        ))
                                      }
                                      <Box sx={{ display: 'flex', alignContent: 'left', justifyContent: 'flex-start', paddingBottom: '5px' }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-evenly", color: "#AAADB4", fontSize: "10px", gap: 1 }}>
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>

                                            <Box><PlayArrowIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfPlay}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><FavoriteIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfLikes}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><RedeemIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfGifts}</Box>
                                          </Box>

                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ))
                          }

                        </Button>
                      </Box>
                    ))
                  }
                </> : <Box sx={{ display: 'flex', gap: 4 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                </Box>
              }
            </Box>
          </Card>
          <Box onClick={() => {handleForward('Korea')}} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretForward size={40} color='rgb(68 114 196)' />
          </Box>
        </Box>
        <Box ref={scroolRef} sx={{ display: 'flex' }}>
          <Box onClick={() => handleBackward('India')} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretBack size={40} color='rgb(68 114 196)' />
          </Box>
          <Card sx={{ margin: 2, boxShadow: 'rgba(31, 32, 33, 0.1) 0px 1px 2px, rgba(31, 32, 33, 0.05) 0px 2px 2px, rgba(31, 32, 33, 0.3) 0px 4px 12px' }}>
            <Box >
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box>{<ElectricBoltIcon style={{ color: 'red', fontSize: '30px' }} />}</Box>
                  <Box sx={{ color: "#662867", fontWeight: 900, fontSize: "20px" }}>India</Box>
                </Box>
                <Box sx={{ color: "blue", fontWeight: 1000, cursor: 'pointer' }} onClick={() => handleExplore('Explore')}>View All</Box>
              </Box>

            </Box>
            <Divider />

            <Box className='explore-card' ref={ IndiaScrollref} sx={{ display: "flex", flexDirection: "row", overflowX: "scroll", padding: '5px', paddingTop: '10px' }}>
              {
                recommended.length !== 0 ? <>
                  {
                    recommended.length && recommended.map((data, index) => (
                      <Box>
                        <Button onClick={() => {
                          navigate(`/record/${data._id}`, {
                            state: { param1: explore, param2: index }
                          })
                        }} style={{ textDecoration: "none" }}>
                          {
                            data.karaokes && data.karaokes.map((val, ind) => (
                              <>
                                <Box sx={{ position: "relative", textAlign: "center", width: "180px", height: '279px', backgroundImage: `url(${data.CoverImageURL?data.CoverImageURL:coverimage})`, borderRadius: '5px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    {
                                      data.PostType === "Video" || "Animation" ? <Box sx={{ display: 'flex', justifyContent: 'end', padding: '3px',alignItems:'center'}}>
                                        <IoIosVideocam style={{ background: 'rgba(128, 128, 128, 0.2)', padding: 1, borderRadius: '5px', paddingTop: 0, paddingBottom: 0 }} fill='white' size={20} />
                                        <Box onClick={(e) => {
                                          e.stopPropagation();
                                        }} sx={{marginTop:1}}
                                        >
                                          {/* <PiDotsThreeVertical style={{paddingTop:0,paddingBottom:0}} fill='white' size={20} /> */}
                                          <BasicMenu id={data.users && data.users[0]?._id} value={val && val._id} />
                                        </Box>
                                      </Box> : ''
                                    }
                                    <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                      <Box sx={{ color: 'rgb(255, 255, 255)', fontWeight: 900, textTransform: "none", fontSize: "14px", textAlign: 'left', paddingLeft: '3px', paddingTop: 0, paddingBottom: 0 }}>
                                        {data === 0 ? 'short-sochege-tumhe-pyar-kre-ke-nhi' : `${val.Name}`}
                                      </Box>
                                      {
                                        data.users && data.users.map((userData, userDataInd) => (
                                          <Box sx={{ display: 'flex', gap: .3, padding: '5px', paddingTop: 0, paddingBottom: 0 }}>
                                            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: "rgb(192, 204, 216)", fontSize: "14px", fontWeight: 400, whiteSpace: 'nowrap', textTransform: 'none' }}>
                                              {data === 0 ? 'ddddd' : `${userData.Name}`}
                                            </Box>
                                            {
                                              data.CollaborationTypeID === 'Duet' ? <Box sx={{ color: 'rgb(192, 204, 216)', fontSize: '12px', fontWeight: 400 }}>+ 1</Box> : ''
                                            }
                                          </Box>

                                        ))
                                      }
                                      <Box sx={{ display: 'flex', alignContent: 'left', justifyContent: 'flex-start', paddingBottom: '5px' }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-evenly", color: "#AAADB4", fontSize: "10px", gap: 1 }}>
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>

                                            <Box><PlayArrowIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfPlay}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><FavoriteIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfLikes}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><RedeemIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfGifts}</Box>
                                          </Box>

                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ))
                          }

                        </Button>
                      </Box>
                    ))
                  }
                </> : <Box sx={{ display: 'flex', gap: 4 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                </Box>
              }
            </Box>
          </Card>
          <Box onClick={() => {handleForward('India')}} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretForward size={40} color='rgb(68 114 196)' />
          </Box>
        </Box>
        <Box>
        {/* forienkeysssssssss */}
        {/* <Box ref={scroolRef}>
        {
          Exploredata && Exploredata.map((data, index) => (
            <Box sx={{ display: 'flex' }}>
            <Box onClick={() => handleBackward('Moments')} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretBack size={40} color='rgb(68 114 196)' />
          </Box>
            <Card sx={{ margin: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box>{data.icon}</Box>
                  <Box sx={{ color: "#662867", fontWeight: 900, fontSize: "20px" }}>{data.tittle}</Box>
                </Box>
                <Box sx={{ color: "blue", fontWeight: 1000, cursor: 'pointer' }} onClick={() => handleExplore('Explore')}>View All</Box>
              </Box>
              <Divider />

              <Box className='explore-card' ref={MomentsScrollRef} sx={{ display: "flex", flexDirection: "row", overflowX: "scroll", padding: '5px', paddingTop: '10px' }}>
              {
                recommended.length !== 0 ? <>
                  {
                    recommended.length && recommended.map((data, index) => (
                      <Box>
                        <Button onClick={() => {
                          navigate(`/record/${data._id}`, {
                            state: { param1: explore, param2: index }
                          })
                        }} style={{ textDecoration: "none" }}>
                          {
                            data.karaokes && data.karaokes.map((val, ind) => (
                              <>
                                <Box sx={{ position: "relative", textAlign: "center", width: "180px", height: '279px', backgroundImage: `url(${data.CoverImageURL?data.CoverImageURL:coverimage})`, borderRadius: '5px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                    {
                                      data.PostType === "Video" || "Animation" ? <Box sx={{ display: 'flex', justifyContent: 'end', padding: '5px' }}>
                                        <IoIosVideocam style={{ background: 'rgba(128, 128, 128, 0.2)', padding: 1, borderRadius: '5px', paddingTop: 0, paddingBottom: 0 }} fill='white' size={20} />
                                        <Box onClick={(e) => {
                                          e.stopPropagation();
                                        }} sx={{ Color: "red" }}
                                        >
                                          {/* <PiDotsThreeVertical style={{paddingTop:0,paddingBottom:0}} fill='white' size={20} /> */}
                                          {/* <BasicMenu id={data.users && data.users[0]?._id} value={val && val._id} />
                                        </Box>
                                      </Box> : ''
                                    }
                                    <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                      <Box sx={{ color: 'rgb(255, 255, 255)', fontWeight: 900, textTransform: "none", fontSize: "14px", textAlign: 'left', paddingLeft: '3px', paddingTop: 0, paddingBottom: 0 }}>
                                        {data === 0 ? 'short-sochege-tumhe-pyar-kre-ke-nhi' : `${val.Name}`}
                                      </Box>
                                      {
                                        data.users && data.users.map((userData, userDataInd) => (
                                          <Box sx={{ display: 'flex', gap: .3, padding: '5px', paddingTop: 0, paddingBottom: 0 }}>
                                            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', color: "rgb(192, 204, 216)", fontSize: "14px", fontWeight: 400, whiteSpace: 'nowrap', textTransform: 'none' }}>
                                              {data === 0 ? 'ddddd' : `${userData.Name}`}
                                            </Box>
                                            {
                                              data.CollaborationTypeID === 'Duet' ? <Box sx={{ color: 'rgb(192, 204, 216)', fontSize: '12px', fontWeight: 400 }}>+ 1</Box> : ''
                                            }
                                          </Box>

                                        ))
                                      }
                                      <Box sx={{ display: 'flex', alignContent: 'left', justifyContent: 'flex-start', paddingBottom: '5px' }}>
                                        <Box sx={{ display: "flex", justifyContent: "space-evenly", color: "#AAADB4", fontSize: "10px", gap: 1 }}>
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>

                                            <Box><PlayArrowIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfPlay}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><FavoriteIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfLikes}</Box>
                                          </Box>.
                                          <Box sx={{ display: "flex", alignItems: "center", gap: .2 }}>
                                            <Box><RedeemIcon style={{ color: 'white' }} fontSize='small' /></Box>
                                            <Box sx={{ color: 'white', fontSize: '12px', fontWeight: 500 }}>{data.NoOfGifts}</Box>
                                          </Box>

                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ))
                          }

                        </Button>
                      </Box>
                    ))
                  }
                </> : <Box sx={{ display: 'flex', gap: 4 }}> */}
                  {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}> */}
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    {/* <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box> */}
                  {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}> */}
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    {/* <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}> */}
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    {/* <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}> */}
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    {/* <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}> */}
                    {/* <SyncLoader
                  color={color}
                  loading={true}
                  size={7}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  /> */}
                    {/* <Skeleton variant='rectangular' width={170} height={200} sx={{ borderRadius: '9px' }} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                    <Skeleton height={20} animation='wave' />
                  </Box>
                </Box>
              }
            </Box> */}





            {/* </Card>
            <Box onClick={() => handleForward('Moments')} sx={{ paddingTop: '180px', cursor: 'pointer' }}>
            <IoCaretForward size={40} color='rgb(68 114 196)' />
          </Box>
        </Box>
          ))
        }</Box> */} 
            </Box>
      </Container>
    </Box>
  )
}

export default Videocard