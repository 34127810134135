import React from 'react'
import Header from './components/header'
import Cardvideo from './components/Card-video'
import { Box } from '@mui/material'
import Cardssong from './components/cards-song'
import Startpage from './components/start-page'
import { useState,useEffect } from 'react'
import Press from '../press'
import Spark from '../spark'
const Home = () => {
  
  const [scrollPosition, setPosition] = useState({  scrollY: 0 })
  useEffect(() => {
   function updatePosition() {
       setPosition({scrollY: window.scrollY })
   }

   window.addEventListener('scroll', updatePosition)
   updatePosition()

   return () => window.removeEventListener('scroll', updatePosition)
  }, [])
  return (
    <Box>
       <Box sx={{backgroundColor:scrollPosition.scrollY===0?"#140E18":"#673EF7",color:"white",padding:1}}>
       <Header/>
        <Cardvideo/>
       </Box>
       <Box sx={{padding:10}}>
        <Cardssong/>
       </Box>
       <Box sx={{backgroundColor:"#4521B6",color:"white",padding:5}}>
        <Startpage/>
       </Box>
       <Box>
        <Spark/>
       </Box>
       
    </Box>
  )
}

export default Home