import { Box, Card, Divider, Typography , Tooltip} from '@mui/material'
import React, { useState , useEffect, useRef} from 'react'
import { useContext } from 'react';
import Switch from '@mui/material/Switch';
import upnext from '../../../assest/images/upnext.jpg'
import NextSong from '../../../data/next-song-data';
import { useLocation , useParams , useNavigate} from 'react-router-dom';
import { url } from '../../../config/config';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { AutoPlayStatus, FullScreenSize, MediaPlay } from '../../../features/addlyric/addLyric';
import img from './guna.jpg';
import { IoIosShuffle } from "react-icons/io";
import { MdSkipPrevious } from "react-icons/md";
import { MdSkipNext } from "react-icons/md";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { FaArrowRotateRight } from "react-icons/fa6";
import { IoRepeat } from "react-icons/io5";
import { FaRegCirclePlay } from "react-icons/fa6";
import { MdOpenInFull } from "react-icons/md";
import { IoVolumeHigh } from "react-icons/io5";
import { BsThreeDots } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { AudioContext } from '..';
import { FaRegPauseCircle } from "react-icons/fa";
import { LuRepeat } from "react-icons/lu";
import { LuRepeat1 } from "react-icons/lu";
import { TbRepeatOff } from "react-icons/tb";
import { PiSpeakerSimpleSlash } from "react-icons/pi";

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const Footersongs = () => {
    // const location = useLocation();
    // const values = location.state.param1;
    // const VideoIndex = location.state.param2;
    const [checked,setChecked] = useState(true)
    const location = useLocation();
    const navigate = useNavigate()
    const [values,setValues] = useState([])
    const params = useParams();
    const dispatch = useDispatch()
    const data = useSelector((data) => data)
    const audioRef = useContext(AudioContext);
    const [play,setPlay] = useState(false);
    const [repeat,setRepeat] = useState(false)
    const [loop,setLoop] = useState(true);
    const scrollContainerRef = useRef(null);
    const [volumeValue,setVolumeValue] = useState(60)
    const maxValueRange = 100;
     let paramID = params.id
    console.log(data)
    useEffect(()=>{

        const GetExplore=async()=>{
   
     try {
           const res = await axios.get(`${url}/api/SongCollaboration/getSongCollaborationForiegnkey`);
           setValues(res.data.data)
       } catch (error) {
         
       }
        }
        GetExplore()
       
      },[])

      useEffect(() => {
        const currentSongIndex = values.findIndex((song) => song._id === paramID);
        if (scrollContainerRef.current && currentSongIndex !== -1) {
            const scrollContainer = scrollContainerRef.current;
            const currentSong = scrollContainer.children[currentSongIndex];
            if (currentSong) {
                const offsetLeft = currentSong.offsetLeft;
                const containerWidth = scrollContainer.offsetWidth;
                const songWidth = currentSong.offsetWidth;

                // Scroll to center the current song
                scrollContainer.scrollTo({
                    left: offsetLeft - (containerWidth / 2 - songWidth / 2),
                    behavior: 'smooth',
                });
            }
        }
    }, [paramID, values]);
      console.log(values)
      function findNextObjectById(array, id) {
        let index = array.findIndex(obj => obj._id === id);
        if (index !== -1 && index < array.length - 1) {
            return array[index + 1];
        } else {
            return null; // Next object not found
        }
    }

    function findPreviousObjectById(array, id) {
        console.log(array,'arrayOfValuesss')
      let index = array.findIndex(obj => obj._id === id);
      console.log(array[index - 1],'IndexValuesssss')
      if (index) {
          return array[index - 1];
      } else {
          return null; // Next object not found
      }
  }
   

      let nextObject = findNextObjectById(values,paramID)
      let prevoiusObject = findPreviousObjectById(values,paramID)
      console.log(prevoiusObject)
      const handleClcik =(id) => {
        navigate(`/record/${id}`);
      }
      console.log(nextObject,prevoiusObject,'DataValuessss For next and previous')
      const handleChange = async (e) => {
         setChecked(e.target.checked)
         const data = await {
            autoplayOn : e.target.checked
         }
         dispatch(AutoPlayStatus(data))
      }
      console.log(checked)
      const handleFullScreen = () => {
        const data = {
            fullscreen : true
        }
        dispatch(FullScreenSize(data))
      }
      const handlePlay = () => {
        audioRef.current.play()
        setPlay(true)
        const data = {
            mediaPlay : false
        }
        dispatch(MediaPlay(data))
      }
      const handlePause = () => {
        audioRef.current.pause()
        setPlay(false)
        const data = {
            mediaPlay : true
        }
        dispatch(MediaPlay(data))
      }

      const handelVolumeController = (e) => {
        console.log(e.target.value,'VoulumeContollerssss')
        setVolumeValue(Number(e.target.value))
         const volume = Number(e.target.value) / maxValueRange
         audioRef.current.volume = volume
      }

  return (
    
    <Box>
        <Card>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",color:"white"}}>
                <Box sx={{display:'flex',gap:12}}>
                    {
                       values && values.map((val,ind) => (
                            <>
                            {
                                paramID === val._id ? <Box sx={{display:'flex',gap:1,padding:'10px'}}>
                                <Box>
                                    <img style={{width:'70px',height:'70px'}} src={val.CoverImageURL}/>
                                </Box>
                                <Box sx={{marginTop:'15px'}}>
                                    <Box sx={{color:'black',width:'200px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',fontWeight:'bold'}}>{val.karaokes[0]?.Artist}</Box>
                                    <Box sx={{color:'black',width:'200px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',fontWeight:'bold'}}>{val.karaokes[0]?.Name}</Box>
                                </Box>
                            </Box> : ''
                            }
                            </>
                        ))
                    }
                <Box sx={{display:'flex',gap:2,marginTop:'25px'}}>
                    <Tooltip title="Shuffle" placement="top">
                    <Box><IoIosShuffle style={{marginTop:'10px',cursor:'pointer'}} size={30} color='black'/></Box>
                    </Tooltip>
                    <Tooltip title="Previous" placement="top">
                    <Box onClick={() => {
                        if(prevoiusObject !== null && prevoiusObject !== undefined){
                            navigate(`/record/${prevoiusObject._id}`)
                        }                 
                    }}><MdSkipPrevious style={{marginTop:'10px',opacity : prevoiusObject !== null && prevoiusObject !== undefined ? 1 : 0.3,cursor:'pointer'}} color='black' size={30}/></Box>
                    </Tooltip>
                    <Tooltip title="Skip back 10 seconds" placement='top'>
                    <Box onClick={() => {
                        audioRef.current.currentTime = audioRef.current.currentTime - 10
                    }}><FaArrowRotateLeft style={{marginTop:'15px',cursor:'pointer'}} color='black' size={20}/></Box>
                    </Tooltip>
                    {
                        data?.Upload.MediaPlayerPlay[0].isTrue ?     <Tooltip title="Play" placement='top'>
                    <Box onClick={handlePlay}><FaRegCirclePlay color='black' size={45} style={{cursor:'pointer'}}/></Box>
                    </Tooltip> : <Tooltip title="Play" placement='top'>
                        <Box onClick={handlePause}><FaRegPauseCircle style={{cursor:'pointer'}} color='black' size={45}/></Box>
                        </Tooltip>  
                    }
                    <Tooltip title='Skip forward 30 seconds' placement='top'>
                    <Box onClick={() => {
                        audioRef.current.currentTime = audioRef.current.currentTime + 30
                    }}><FaArrowRotateRight color='black' style={{marginTop:'15px',cursor:'pointer'}} size={20}/></Box>
                    </Tooltip>
                    <Tooltip title='Next' placement='top'>
                    <Box onClick={() => {
                        if(nextObject !== null && nextObject !== undefined){
                            navigate(`/record/${nextObject._id}`)
                        }
                    }}><MdSkipNext color='black' style={{marginTop:'10px',opacity : nextObject !== null && nextObject !== undefined ? 1 : 0.3,cursor:'pointer'}} size={30}/></Box>
                    </Tooltip>
                    {
                        repeat ?  <Tooltip title='Repeat' placement='top'>
                        <Box onClick={() => {
                            setRepeat(false)
                            setLoop(true)
                        }}><LuRepeat1 color='black' style={{marginTop:'10px',cursor:'pointer'}} size={30}/></Box>
                        </Tooltip> : loop ?  <Tooltip title='Repeat' placement='top'>
                    <Box onClick={() => {setRepeat(true)}}><LuRepeat color='black' style={{marginTop:'10px',cursor:'pointer'}} size={30}/></Box>
                    </Tooltip> :  <Tooltip title='Repeat' placement='top'>
                    <Box onClick={() => {
                        setRepeat(true)
                    }}><TbRepeatOff color='black' style={{marginTop:'10px'}} size={30}/></Box>
                    </Tooltip>
                    }
                </Box>
                </Box>
                <Box sx={{display:'flex',gap:2}}>
                    <Box sx={{display:'flex',gap:1}}>
                        {
                            volumeValue === 0 ? <Tooltip title="Volume" placement='top'>
                            <Box sx={{cursor:'pointer'}} className='mediaVolume' onClick={() => {
                                setVolumeValue(60)
                                audioRef.current.volume = 0.6
                            }}><PiSpeakerSimpleSlash color='black' size={25} style={{marginTop:'5px'}}/></Box>
                            </Tooltip> : <Tooltip title="Volume" placement='top'>
                    <Box sx={{cursor:'pointer'}} className='mediaVolume' onClick={() => {
                                setVolumeValue(0)
                                audioRef.current.volume = 0
                            }}><IoVolumeHigh color='black' size={25} style={{marginTop:'5px'}}/></Box>
                    </Tooltip>
                        }
                    <Box  className='soundTab' sx={{marginTop:'10px',cursor:'pointer'}}>
                        <input style={{width:'70px'}} type='range' min={0} max={maxValueRange} value={volumeValue} onChange={handelVolumeController}/>
                    </Box>
                    </Box>
                    <Tooltip  title="Full screen" placement='top'>
                    <Box sx={{cursor:'pointer'}} onClick={handleFullScreen}><MdOpenInFull color='black' size={25} style={{marginTop:'5px'}}/></Box>
                    </Tooltip>
                    <Box  sx={{color:'black'}}>Autoplay  <Switch checked={checked} onChange={handleChange} {...label} defaultChecked={true}/></Box>
                    <Tooltip title="More" placement='top'>
                    <Box sx={{cursor:'pointer'}}><BsThreeDots color='black' size={25} style={{padding:'5px'}}/></Box>
                    </Tooltip>
                </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", color: "white" }}>
                    <Box sx={{ display: 'flex', gap: 1 }} ref={scrollContainerRef} style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
                        {values.map((val, ind) => (
                            <Box
                                key={val._id}
                                sx={{
                                    display: 'inline-block',
                                    textAlign: 'center',
                                    backgroundColor: paramID === val._id ? 'darkblue' : 'transparent',
                                    color: paramID === val._id ? 'white' : 'black',
                                    padding: '10px',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleClcik(val._id)}
                            >
                                <Box>
                                    <img style={{width:'204px',height:'204px'}} src={val.CoverImageURL} alt={'loading'} />
                                </Box>
                                <Typography sx={{ fontWeight: 'bold', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',textAlign:'center',width:'180px'}}>
                                    {val.karaokes[0]?.Name}
                                </Typography>
                                <Typography sx={{ fontSize: '12px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',textAlign:'center'}}>
                                    {val.karaokes[0]?.Artist}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
        </Card>
    </Box>
  )
}

export default Footersongs