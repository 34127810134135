import { Avatar, Box, Card, Container, Divider, TextField, Typography,Button } from '@mui/material'
import React, { useState,useEffect } from 'react'
import girl from '../assest/images/girl.webp'
import Switch from '@mui/material/Switch'; 
import axios from 'axios';
import { url } from '../config/config';
const Settings = () => {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const[userProfileData,setUserProfileData]=useState([])
  const [userEmail,setUserEmail]=useState()
  const [userPassword, setUserPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  console.log(userProfileData,"iyiuyiutvuytcurctrctrc")
  useEffect(() => {
    const GetProfileData = async () => {
      try{
        const config={
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/User/getUserById/${localStorage.getItem('token')}`,config)
        setUserProfileData(res.data.data)
        console.log(res.data.data,'dataValue')
         const email = await res.data.data[0].Email;
        setUserEmail(email.split('@'))
      }catch (error){
         console.log(error)
      }
    }
    GetProfileData()
   },[])
   useEffect(()=>{
    const changepass=async()=>{
 try {
       const res = await axios.get(`${url}/api/User/VerfifyUserEmail`);
       
   } catch (error) {
      console.log(error)
   }
    }
    changepass()
  },[])
   const [newPassword, setNewPassword] = useState('');
  const [userconfirmPassword, setUserconfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setUserconfirmPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    // Handle password change logic here
    console.log("Password changed successfully!");
    // Clear the form
    setNewPassword('');
    setConfirmPassword('');
    setError('');
  };
  const handleEmailChange = (e) => {
    setUserEmail(e.target.value); // Update email state
  };

  const handlePasswordChange = (e) => {
    setUserPassword(e.target.value); // Update password state
  };
  const handleConfirmPasswordChanges = (e) => {
    setConfirmPassword(e.target.value); // Update confirm password state
  };

  return (
    
    <Container maxWidth='lg'>
      <Card sx={{padding:2,mt:3,backgroundColor:"white",color:"black"}}>
   <Box sx={{display:"flex",flexDirection:"column",gap:4}}>
   <Box sx={{backgroundColor:"#C0C0C0",padding:"20px",color:"black"}}>
          <Box><Typography fontWeight={700} fontSize={20}>Settings</Typography></Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
        <Box sx={{display:"flex",alignItems:"center",gap:6}}>
          <Box><Typography fontWeight={600}>Profile images</Typography></Box>
          <Box><Avatar sx={{width:90,height:90}} alt="Remy Sharp" src={userProfileData[0]?.ProfilePhoto} /></Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
        <Box sx={{display:"flex",alignItems:"center",gap:16}}>
          <Box><Typography fontWeight={600}>Blurb</Typography></Box>
          <Box>
          <TextField
        variant="outlined"
        value={userProfileData[0]?.Name || ''}
        InputProps={{
          style: {
            fontSize: 20,
            fontWeight: 500,
            color: 'black',
            backgroundColor: 'white',
          },
        }}
        fullWidth
      />
      </Box>
          {/* <Box><Typography fontSize={20} fontWeight={500} color={"black"} backgroundColor={"white"} width={"400px"}>{userProfileData[0]?.Name}</Typography></Box> */}
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
        <Box sx={{display:"flex",alignItems:"center",gap:13}}>
          <Box><Typography fontWeight={600}>Location</Typography></Box>
          
          <Box>
          <TextField
        variant="outlined"
        value={userProfileData[0]?.Bio || ''}
        InputProps={{
          style: {
            fontSize: 20,
            fontWeight: 500,
            color: 'black',
            backgroundColor: 'white',
          },
        }}
        fullWidth
      />
      {/* <Typography fontSize={20} fontWeight={500} color={"black"} backgroundColor={"white"} width={"400px"}>{userProfileData[0]?.Bio}</Typography> */}
      </Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
   </Box>
      </Card>
      <Card sx={{padding:2,mt:3,backgroundColor:"white",color:"black"}}>
   <Box sx={{display:"flex",flexDirection:"column",gap:4}}>
   <Box sx={{backgroundColor:"#C0C0C0",padding:"20px",color:"black"}}>
          <Box><Typography fontWeight={700} fontSize={20}>Login Information</Typography></Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
      
        <Box sx={{display:"flex",alignItems:"center",gap:16}}>
          <Box><Typography fontWeight={600}>Name</Typography></Box>
          <Box sx={{display:"flex",gap:6}}>
            <Box>
            <Box sx={{display:"flex",gap:6}}>
            <Box>
            <Box width={"400px"}>
      <TextField
        variant="outlined"
        value={userProfileData[0]?.Name || ''}
        InputProps={{
          style: {
            fontSize: 20,
            fontWeight: 500,
            color: 'black',
            backgroundColor: 'white',
          },
        }}
        fullWidth
      />
    </Box>
  
            </Box>
            <Box>
           {/* <Typography fontWeight={600} color="white">https://www.SparkSinger.com/RanjithKumar2037</Typography> */}
  
            </Box>
          </Box>
            {/* <Box><Typography fontSize={20} fontWeight={500} color={"black"}  backgroundColor={"white"} width={"400px"}>{userProfileData[0]?.Name}</Typography></Box> */}
  
            </Box>
          </Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
      
        <Box sx={{display:"flex",alignItems:"center",gap:12}}>
          <Box><Typography fontWeight={600}>Username</Typography></Box>
          <Box sx={{display:"flex",gap:6}}>
            <Box>
            <Box width={"400px"}>
      <TextField
        variant="outlined"
        value={userProfileData[0]?.Name || ''}
        InputProps={{
          style: {
            fontSize: 20,
            fontWeight: 500,
            color: 'black',
            backgroundColor: 'white',
          },
        }}
        fullWidth
      />
    </Box>
  
            </Box>
            <Box>
           {/* <Typography fontWeight={600} color="white">https://www.SparkSinger.com/RanjithKumar2037</Typography> */}
  
            </Box>
          </Box>
          
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
        <Box sx={{display:"flex",alignItems:"center",gap:16}}>
          <Box><Typography fontWeight={600}>Email</Typography></Box>
          <Box width={"400px"}>
      <TextField
        variant="outlined"
        value={userProfileData[0]?.Email || ''} 
        InputProps={{
          style: {
            fontSize: 20,
            fontWeight: 500,
            color: 'black',
            backgroundColor: 'white',
          },
        }}
        fullWidth
      />
    </Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
        <Box sx={{display:"flex",gap:12}}>
          <Box><Typography fontWeight={600}>Password</Typography></Box>
          <Box><Typography fontSize={20} fontWeight={500} color={"black"}  backgroundColor={"white"} width={"400px"}>************</Typography></Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
        <Box sx={{backgroundColor:"#C0C0C0",padding:"20px",color:"black"}}>
          <Box><Typography fontWeight={700} fontSize={20}> Change Password</Typography></Box>
        </Box>
      
        <Box component="form" sx={{ display: 'flex', width: '400px',gap:3 }}>
      {/* Use a Box to wrap the TextFields and display them in a row */}
      <Box sx={{ display: 'flex', marginBottom: 2 ,gap:3}}>
      <Box><Typography fontWeight={600}>Password</Typography></Box>
        <TextField
          label="Email"
          variant="outlined"
          value={userEmail}
          onChange={handleEmailChange}
          required 
          sx={{ marginBottom: 5,width:250 }} 
        />
        <TextField
          label="New Password"
          type="password" 
          variant="outlined"
          value={userPassword}
          onChange={handlePasswordChange}
          required 
          sx={{ marginBottom: 2,width:250 }} 
        />
        <TextField
          label="Confirm New Password"
          type="password"
          variant="outlined"
          value={userconfirmPassword} // Bind confirm password state
          onChange={handleConfirmPasswordChange} // Update confirm password state
          required
          sx={{ marginBottom: 2,width:250}} // Add margin below the field
        />
      </Box>
      {/* The button will be in the next row */}
      <Box sx={{ display: 'flex', justifyContent: "center", gap: 1,marginTop:2 }}>
        <Button variant='contained' sx={{ height: 30, fontSize: 13 }} >Cancel</Button>
        <Button variant='contained' sx={{ height: 30, fontSize: 13 }} > Save </Button>
                                    
                                </Box>
    </Box>
  

   </Box>
      </Card>
      <Card sx={{padding:2,mt:3,backgroundColor:"white"}}>
   <Box sx={{display:"flex",flexDirection:"column",gap:4,color:"black"}}>
   <Box sx={{backgroundColor:"#C0C0C0",padding:"20px",color:"black"}}>
          <Box><Typography fontWeight={700} fontSize={20}>SparkSinger Emails</Typography></Box>
        </Box>
        <Divider sx={{backgroundColor:"white"}}></Divider>
      
      
      
       
   </Box>
   <Box sx={{display:"flex",justifyContent:"space-between",color:"black"}}>
    <Box><Typography fontWeight={600}>Receive Product Updates from SparkSinger</Typography></Box>
    <Box><Typography><Switch {...label} defaultChecked /></Typography></Box>
   </Box>
      </Card>
    </Container>
  )
}

export default Settings