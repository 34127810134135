import { Box, Button, Typography } from '@mui/material';
import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';

const Paragraph = () =>{
    
    const location = useLocation(); // Use useLocation to access the passed state
    const { content } = location.state || {}; // Extract content from state
  
    return (
      <Box sx={{ padding: 2, margin: 2, border: '1px solid #6C3FEA', borderRadius: 4 }}>
        {content ? (
          <Typography variant="h5">{content}</Typography>
        ) : (
          <Typography variant="h5">No content available.</Typography>
        )}
      </Box>
    );
}
export default Paragraph;