import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Link, Outlet } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Divider, Drawer, colors, Modal, Card } from '@mui/material';
import LeftDrawer from '../components/left-drawer';
import BasicMenu from '../components/basic-menu';
import logo from '../assest/images/Bright Color-01.png';
import newlogo from '../assest/images/PNG-06.png'
import newwhitelogo from '../assest/images/NewLogoWhite.png'
import logo1 from '../assest/images/resized-removebg-preview.png'
import './styles/top_Nav.css'
import Authmodel from '../components/auth_model';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { url } from '../config/config';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import NotificationDrawer from '../components/notificationDrawer';
import io from 'socket.io-client';
import MoreIconDrawer from './more';
import { ToastContainer, toast } from 'react-toastify';
import { useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Cancel, ExitToApp } from '@mui/icons-material';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { FaArrowRight } from 'react-icons/fa6';

const socket = io(url, {
  transports: ['websocket'], // Ensure using WebSocket transport
  reconnectionAttempts: 5, // Set reconnection attempts if needed
}); const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));
const style = {
  position: 'absolute',
  top: { xl: '4%', lg: '6%', md: '6%' },
  left: '45%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '5px',


};
const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  pt: 2,
  px: 4,
  pb: 3,
  boxShadow: '1px 1px 20px black',
  p: 4,
  borderRadius: 2
};
export default function Topnav() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchor, setanchor] = React.useState(false)
  const [notification, setNotification] = useState(false);
  const [open, setopen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [modal, setModal] = useState(false)
  const [more, setMore] = useState(false)
  const [logout, setLogut] = useState(false)
  const DrawerRef = React.useRef(0);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const userTokenData = localStorage.getItem('token') !== null ? jwtDecode(localStorage.getItem('token')) : ''
  const navigate = useNavigate()
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const Anchor = () => {
    setanchor(false)
  }
  const HandleClose = () => {
    setopen(false)
  }
  const handleClicklogut = () => {
    setLogut(true)
  }
  const [userData, setUserData] = useState([])
  useEffect(() => {
    const GetProfileData = async () => {
      try {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        const res = await axios.get(`${url}/api/User/getUserById/${localStorage.getItem('token')}`, config)
        console.log(res,'respomse Array')
        if(res.data){
          setUserData(res.data.data)
        }
        console.log(res.data.data)
        localStorage.setItem('userID', res.data.data[0]?._id)
      } catch (error) {
        console.log(error)
      }
    }
    GetProfileData()
  }, [])
  console.log(userData,'DataVlauesssFor User')
  const [notificationCount, setNotificationCount] = useState([])
  const fetchNotifications = useCallback(() => {
    socket.emit('fetchNotifications', localStorage.getItem('token') ?? null);
  }, []);

  const handleNotification = React.useCallback((data) => {
    const { notification, senderId, songCollaborationId } = data;

    console.log(data, 'notigy log');

    toast.info(`New Notification from ${senderId}: ${notification}`, {
      position: "top-right",
      autoClose: 3000,

    });
  }, []);

  const handleNotificationCount = (data) => {
    console.log('callingtheNotifaction')
    console.log(data,'datavAluessssss')
    setNotificationCount(data);
  }

  useEffect(() => {
    console.log('socket call');
    socket.on("connect", () => {
      console.log('web socket connected');
    });
    socket.emit('register', userTokenData.userId)
    socket.on('NotifyCount', handleNotificationCount);

    // Cleanup function to disconnect the socket
    return () => {
      socket.off("connect");
      socket.off('NotifyCount', handleNotification);

    };
  }, []);


  console.log(notificationCount)
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{ marginLeft: -2, marginTop: 7.5, borderRadius: '10px' }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem sx={{ borderBottom: '2px solid rgb(240, 242, 244)', width: "160px" }} onClick={handleMenuClose}>
        <Link style={{ textDecoration: "none", color: "black" }} to='profile' >Profile</Link>
      </MenuItem>
      <MenuItem sx={{ borderBottom: '2px solid rgb(240, 242, 244)' }} onClick={handleMenuClose}>
        <Link style={{ textDecoration: "none", color: "black" }} to='notification' >Notifications</Link>
      </MenuItem>
      <MenuItem sx={{ borderBottom: '2px solid rgb(240, 242, 244)' }} onClick={handleMenuClose}>
        <Link style={{ textDecoration: "none", color: "black" }} to='settings' >Settings</Link>
      </MenuItem>
      <MenuItem onClick={() => {
        localStorage.removeItem('token')
        localStorage.removeItem('userID')
        window.location.reload()
      }}>Log out</MenuItem>
    </Menu>
  );
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  console.log(localStorage.getItem('userID'))
  // console.log(notificationCount)
  const handleClick = () => {
    if(localStorage.getItem('token') !== null){
      if (notificationCount.length > 0) {
        try {
          const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
          const data = {
  
          }
          const res = axios.patch(`${url}/api/Notification/NotificationViewedWebByUserID`, data, config)
          console.log(res.data)
          setNotification(true)
        } catch (error) {
          console.log(error)
        }
      } else {
        setNotification(true)
      }
    }else {
      setopen(true)
    }
    
  }
  const handleModalClose = () => {
    setModal(false)
  }

  const handleMoreClose = (val) => {
    setMore(val)
  }

  const handleSearch = () => {
    setModal(false)
    navigate(`search/${searchValue}`)
  }
  const handleProfileClick = () => {
    if (localStorage.getItem('token') !== null) {
      navigate('/profile');
    } else {
      navigate('/')
      setopen(true);

    }
  }
  const handleSettingsclick = () => {
    if (localStorage.getItem("token") !== null) {
      navigate('/settings')
    } else {
      navigate('/')
      setopen(true);

    }
  }

  const notificationCose = (val) => {
    console.log(val)
    setNotification(val)
  }

  const notificationControl = (val) => {
    setNotification(val)
  }
  return (
    <Box sx={{ flexGrow: 1 }}>

      <AppBar position="sticky"
        sx={{ backgroundColor: "#140E18", boxShadow: "none" }}
      >
        <Toolbar>
          <Box sx={{ backgroundColor: "#3f13a8", padding: "15px", marginLeft: "-24px", height: '100%' }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setanchor(true)
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box> <Link style={{ textDecoration: "none", color: "white", marginLeft: '9px' }} to='/'>
            <img style={{ width: "180px", marginTop: '3px' }} src={logo}></img></Link></Box>
          <Divider orientation="vertical" flexItem sx={{ backgroundColor: '#F7C1F719', marginLeft: '10px' }} />
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: "20px", marginLeft: "30px", fontSize: "17px", alignItems: "center" }}>
            <Box>
              <Link style={{ textDecoration: "none", color: "white" }} to='explore'><Typography sx={{
                color: "White",
                "&:hover": {
                  color: "green"
                },
              }}>Explore</Typography></Link>
            </Box>
            <Box> <Link style={{ textDecoration: "none", color: "white" }} to='song'><Typography sx={{
              color: "White",
              "&:hover": {
                color: 'green'
              },
            }}>Songbook</Typography></Link></Box>
            <Box sx={{ display: "flex", alignItems: "c" }}>
              <Box>
                <BasicMenu />
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }} onClick={() => { setModal(true) }}>
            <SearchIcon style={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              {
                localStorage.getItem('token') ?
                  <Typography sx={{ fontWeight: 'bold', color: '#673EF7', textTransform: 'uppercase', fontSize: '20px' }}>{userData?.length != 0 && userData[0]?.Name}</Typography> : ''
              }
            </Box>
            <Box className='subscribeIcon' onClick={() => navigate('/pricing')} sx={{ fontSize: "17px", cursor: 'pointer', display: { xs: 'none', md: 'block' },fontWeight:'bold',color:'#ff5733'}}>
              Get Premium
            </Box>
            <Box className='topnav-icons' sx={{ display: { xs: 'none', md: 'flex' }, gap: 1 }}>
              <Box sx={{ cursor: 'pointer' }} onClick={handleClick}>
                <NotificationsIcon /> {
                  notificationCount.length > 0 ? <span style={{ position: 'absolute', zIndex: 1, backgroundColor: 'rgb(253, 40, 110)', borderRadius: '50%', height: '14px', width: '14px', fontSize: '11px', textAlign: 'center', right: 185 }}>{notificationCount.length}</span> : ''
                }
              </Box>
              <Box sx={{ cursor: 'pointer' }}>
                <SettingsIcon onClick={handleSettingsclick} />
              </Box>
              <Box sx={{ cursor: 'pointer' }}>
                <PersonIcon onClick={handleProfileClick} />
              </Box>
              <Box sx={{ cursor: 'pointer' }}>
                <LogoutIcon onClick={handleClicklogut} />
              </Box>
              <Modal
                open={logout}
                onClose={() => setLogut(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                
              >
                <Box sx={styles}>
                <Cancel sx={{marginLeft:35,cursor:'pointer'}} onClick={()=> setLogut(false)}></Cancel>
                  
                 <Box sx={{display:'flex',textAlign:"center",justifyContent:"center",fontSize:"60px",color:"green"}}><IoAlertCircleOutline /></Box> 
               
                  <Box ><Typography sx={{ fontWeight: "bold", fontSize: 30,textAlign:"center",mt:3 }}>Are you leaving?</Typography></Box>
                  
                    <Typography id="modal-modal-title" variant="h6" component="h2" mt={2}>
                     Are you sure want to log out? All your unsaved data will be lost
                    </Typography>
                  <Box mt={2} sx={{ display: 'flex', justifyContent: "end", textAlign: "center", gap: 1 }}>
                  <Button  onClick={() => setLogut(false)}>Cancel</Button>
                    <Button variant='contained' onClick={() => {
                      localStorage.removeItem('token')
                      localStorage.removeItem('userID')
                      setLogut(false)
                      navigate('/')
                    }}>yes < FaArrowRight></FaArrowRight></Button></Box>

                    
                </Box>

              </Modal>
              <Box>
              </Box>
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' } }} onClick={() => { setMore(true) }}>
              <MenuIcon />
            </Box>
            <Box>
              {
                localStorage.getItem('token') ? <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <Avatar alt="Remy Sharp" src={userData.length != 0 && userData[0].ProfilePhoto} />
                </IconButton> : <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={() => {
                    setopen(true)
                  }}
                  color="inherit"
                >
                  <Button sx={{ color: "white" }} variant="outlined">Login</Button>
                </IconButton>
              }
              <Authmodel open={open} HandleClose={HandleClose} />
            </Box>
          </Box>
          {/* <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box> */}
        </Toolbar>
        <ToastContainer />

      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Drawer anchor='left'
        open={anchor}
        PaperProps={{
          sx: {
            backgroundColor: "#2D2867",
            color: "white",
          }
        }}
        onClose={() => { setanchor(false) }}
      >
        <Box >
          <LeftDrawer notificationControl={notificationControl} Anchor={Anchor} />
        </Box>
      </Drawer>
      <Drawer anchor='right'
        open={notification}
        PaperProps={{
          sx: {
            backgroundColor: 'white',
          }
        }}
        onClose={() => { setNotification(false) }}
      >
        <Box>
          <NotificationDrawer onNotificationClose={notificationCose} value={notification} />
        </Box>
      </Drawer>
      <Outlet />
      <Modal
        open={modal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', borderRadius: '5px' }}>
            <Box sx={{ display: 'flex', margin: '5px' }}>
              <Box sx={{ padding: '5px', textAlign: 'center' }}>
                <SearchIcon style={{ fontSize: '28px' }} />
              </Box>
              <Box>
                <input placeholder="Search artists, song, users..." onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch()

                  }
                }} className='search-box' style={{ width: '100%', height: '90%', color: 'rgb(45, 40, 103)', fontSize: '17px', fontWeight: 'bold', border: 'none' }} value={searchValue} onChange={(e) => { setSearchValue(e.target.value) }} />
              </Box>
            </Box>
            {
              searchValue !== '' ? <Box onClick={handleSearch} sx={{ margin: '5px' }}>
                <Button sx={{ background: 'rgb(108, 46, 255)', fontSize: '17px', color: 'white', fontWeight: 'bold', height: '40px', textTransform: 'none', cursor: 'pointer' }}>Search</Button>
              </Box> : <Box onClick={() => { setModal(false) }} sx={{ color: 'rgb(108, 46, 255)', fontSize: '15px', fontWeight: 'bold', textAlign: 'center', alignItems: 'center', padding: '15px', cursor: 'pointer' }}>Cancel</Box>
            }
          </Box>
        </Box>
      </Modal>
      <Drawer anchor='top'
        open={more}
        PaperProps={{
          sx: {
            backgroundColor: 'rgb(138 89 255)',
          }
        }}
        onClose={() => { setMore(false) }}
      >
        <Box>
          <MoreIconDrawer onClose={handleMoreClose} />
        </Box>
      </Drawer>

    </Box>

  );
}